import React, { Component } from "react";

const TrustMicro = ({ trustMicroRef }) => (
	<div ref={trustMicroRef}
		 className="trustpilot-microstar-widget"
		 data-locale="en-GB"
		 data-template-id="5419b732fbfb950b10de65e5"
		 data-businessunit-id="573ed1070000ff00058d1d66"
		 data-style-height="20px"
		 data-style-width="100%"
		 data-theme="light">
		<a href="https://uk.trustpilot.com/review/www.cashback.co.uk" target="_blank"
		   rel="noopener">Trustpilot</a>
	</div>
);

class TrustPilotMicroStar extends Component {
	constructor (props) {
		super(props);
		this.trustMicroRef = React.createRef();
	}

	componentDidMount () {
		if (window.Trustpilot) {
			window.Trustpilot.loadFromElement(this.trustMicroRef.current, true);
		}
	}

	componentDidUpdate () {
		if (window.Trustpilot) {
			window.Trustpilot.loadFromElement(this.trustMicroRef.current, true);
		}
	}

	render () {
		return <TrustMicro trustMicroRef={this.trustMicroRef} />;
	}
}

export default TrustPilotMicroStar;