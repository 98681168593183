import { getToken } from "./tracking";
import { local, session } from "./storage";
import ApiFunc from "../Components/ApiCalls/ApiFunc";
import { getCookie, setCookie } from "./cookies";

const RECAPTCHA_KEY = "6Ld7d-8bAAAAAMpxYoWZKrnudHqSWA_xE8V6v8d2";

function runCheck () {
	let run = true;
	if (navigator.userAgent.includes("Lighthouse")
		|| window.location.hostname === "localhost"
		|| getToken()) {
		run = false;
	}

	return run;
}

export function recaptchaExecute (callback) {
	if (!(runCheck() && window?.grecaptcha?.ready)) {
		callback();
		return;
	}

	window.grecaptcha.ready(() => {
		window.grecaptcha.execute(RECAPTCHA_KEY, { action: "submit" }).then(token => {
			callback(token);
		});
	});
}

const loadScriptByURL = (id, url, callback) => {
	const isScriptExist = document.getElementById(id);

	if (!isScriptExist) {
		let script = document.createElement("script");
		script.type = "text/javascript";
		script.src = url;
		script.id = id;
		script.onload = () => {
			if (callback) {
				callback();
			}
		};
		document.body.appendChild(script);
	}

	if (isScriptExist && callback) {
		callback();
	}
};

export function initRecaptcha () {
	if (!runCheck()) {
		return;
	}

	// load the script by passing the URL
	loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`);
}

window.anuraResponseHandler = (response) => {
	var anuraId = response.getId();
	if (!anuraId) {
		window.sentry.captureMessage("anura missing data");
		return;
	}
	window.ANURA_ID = anuraId;

	const trackFields = {};

	if (local.getItem("track_uuid") !== null) {
		trackFields.track_uuid = local.getItem("track_uuid");
	}

	trackFields.quality_id = "anura_" + anuraId;
	session.setItem("quality_id", trackFields.quality_id);

	ApiFunc("tracking/track", "POST", trackFields)
		.then(response => response.json())
		.then(data => {
			if (data.track_info) {
				local.setItem("track_uuid", data.track_info.track_uuid);
			}
		})
		.catch(error => {
			console.error("track_uuid error:", error);
		});
};

export function initAnura () {
	if (window?.Anura || getCookie("anura_init")) {
		//Anura not required - Quitting
		return;
	}

	;(function() {
		var PREPEND_IDENTIFIER = "CB_";
		var anura = document.createElement("script");
		if (typeof anura === "object") {
			var url_params_obj = {};
			window.location.search.slice(1).split("&").forEach(function(item) {url_params_obj[item.split("=")[0]] = item.split("=").slice(1).join("");});

			var additional_str = "";
			try {
				additional_str = JSON.stringify({
					"1": PREPEND_IDENTIFIER + (url_params_obj["utm_source"] || "na"),
					"2": PREPEND_IDENTIFIER + (url_params_obj["utm_medium"] || "na"),
					"3": PREPEND_IDENTIFIER + (url_params_obj["utm_campaign"] || "na"),
					"4": PREPEND_IDENTIFIER + (url_params_obj["utm_term"] || "na"),
					"5": PREPEND_IDENTIFIER + (url_params_obj["gclid"] || "na")
				});
			} catch (e) {}
			var request = {
				instance: 3541778917,
				source: PREPEND_IDENTIFIER + (url_params_obj["utm_source"] || "na"),
				campaign: PREPEND_IDENTIFIER + (url_params_obj["utm_campaign"] || "na"),
				additional: additional_str,
				callback: "anuraResponseHandler"
			};
			var params = [];
			for (var x in request) params.push(x + "=" + encodeURIComponent(request[x]));
			params.push(Math.floor(1E12 * Math.random() + 1));
			anura.type = "text/javascript";
			anura.async = true;
			anura.src = "https://script.anura.io/request.js?" + params.join("&");
			var script = document.getElementsByTagName("script")[0];
			script.parentNode.insertBefore(anura, script);

			setCookie("anura_init", "true", 1);
		}
	})();
}