import React, { Component } from "react";
import * as Sentry from "@sentry/react";

export default class ErrorBoundary extends Component {
	constructor (props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError (error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true, error };
	}

	componentDidCatch (error, errorInfo) {
		if (error.name !== "ChunkLoadError") {
			Sentry.captureException(error, { extra: errorInfo });
		}
	}

	render () {
		const { error, hasError } = this.state;
		if (hasError) {
			if (error.name === "ChunkLoadError") {
				location.reload();
			}

			if(process.env.NODE_ENV !== "development"){
				setTimeout(() => {
					location.reload();
				}, 5000);
			}

			return <p style={{
				display: "grid",
				minHeight: "100vh",
				placeSelf: "center",
				alignItems: "center"
			}}>
				An error has occurred, please refresh and try again.
			</p>;
		}

		return this.props.children;
	}
}