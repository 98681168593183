export function isIOS () {
	return [
			"iPad Simulator",
			"iPhone Simulator",
			"iPod Simulator",
			"iPad",
			"iPhone",
			"iPod"
		].includes(navigator.platform)
		// iPad on iOS 13 detection
		|| (navigator.userAgent.includes("Mac") && "ontouchend" in document);
}

export function detectDeviceFamily () {
	try {
		var userAgent = navigator.userAgent || navigator.vendor || window.opera;

		// Windows Phone must come first because its UA also contains "Android"
		if (/android/i.test(userAgent)) {
			return "android";
		}

		// iOS detection from: http://stackoverflow.com/a/9039885/177710
		if (/iPad|iPhone/.test(userAgent) && !window.MSStream) {
			return "ios";
		}
	} catch (e) {}

	return "";
}

export function isSafari() {
	if(typeof navigator === 'undefined') {
		return false;
	}

	return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function isFirefox() {
	if(typeof navigator === 'undefined') return false

	return navigator.userAgent.toLowerCase().indexOf('firefox') > -1
}