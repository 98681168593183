import React from "react";
import { createRoot } from 'react-dom/client';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./App";

Sentry.init({
	environment: process.env.NODE_ENV === "development" ? "development" : "production",
	dsn: "https://339a5cf951e64c5589cfa7f33cd0d3b5@sentry.stechga.co.uk/42",
	integrations: [new Integrations.BrowserTracing({
		tracingOrigins: ["localhost:8000", "www.cashback.co.uk", "api.cashback.co.uk", /^\//]
	})],
	tracesSampleRate: 0.2,
	sampleRate: process.env.NODE_ENV === "development" ? 1 : 0.2,
	ignoreErrors: [
		'Script error.',
		// Random plugins/extensions
		'top.GLOBALS',
		// See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
		'originalCreateNotification',
		'canvas.contentDocument',
		'MyApp_RemoveAllHighlights',
		'http://tt.epicplay.com',
		'Can\'t find variable: ZiteReader',
		'jigsaw is not defined',
		'ComboSearch is not defined',
		'http://loading.retry.widdit.com/',
		'atomicFindClose',
		// Facebook borked
		'fb_xd_fragment',
		// ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
		// reduce this. (thanks @acdha)
		// See http://stackoverflow.com/questions/4113268
		'bmi_SafeAddOnload',
		'EBCallBackMessageReceived',
		// See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
		'conduitPage',
		'Cannot read property \'DOMNodeInsertedByJs\' of undefined', // Jornaya/leadid
		'Blocked a frame with origin "https://usproducttesting.com" from accessing', // clarity.js via bing bork
		'Permission denied to access property "navigator" on cross-origin object', // some 3rd party, think clarity.js
		'SyntaxError: \':-webkit-autofill\' is not a valid selector', // some 3rd party jank
		'TypeError ?(webkit-masked-url://hidden/)', // webpack bug
		// cookie banner jank
		/scripttemplates/,
		// microsoft preview jank
		/Non-Error promise rejection captured/,
		'Can\'t find variable: al_onPoststitialDismiss'
    ],
    denyUrls: [
        /cookielaw\.org/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // google ads
        /pagead2\.googlesyndication\.com/i,
        // vwo
        /dev\.visualwebsiteoptimizer\.com/i,
        // recaptcha internal bugs
        /gstatic\.com\/recaptcha/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        /trustedform\.com/i,
        /cdn\.trustedform\.com/i,
        /moatads\.com/i, // browser ad virus
        // tiktok borking on iphone
        /analytics\.tiktok\.com/i,
        /webkit-masked-url/,
        /clarity/,
        '<anonymous>',
	'/__puppeteer_evaluation_script__/'
    ]
});

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<App />);
