import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import LoginForm from "./LoginForm";
import { withAlert } from "react-alert";

class LoginBox extends Component {
	render () {
		const closeBtn = <span className="modal-close"
							   tabIndex="0"
							   role="button"
							   aria-label="Close"
							   onKeyUp={e => {
								   if (e.key === "Enter" || e.key === "Escape") {
									   this.props.handleClose();
								   }
							   }}
							   onClick={this.props.handleClose}>×</span>;

		let signupButton = <button className="btn signup-btn" onClick={() => {
			this.props.switchModal("signup");
		}}>Join now for FREE!</button>;

		if (this.props.hideSignUp === true) {
			signupButton = <></>;
		}

		return <div className={(this.props.show ? "overlay" : "overlay hide") +
			(this.props.hideClose ? " allow-clicks" : "")}>
			<div className="modal login">
				<div className="login-title">
					<h2>Login to your account below</h2>
					{this.props.hideClose ? "" : closeBtn}
				</div>
				<div className="login-form">
					<LoginForm
						redirectTo={this.props.redirectTo ?? "/"}
						onLogin={this.props.onLogin}
					/>
					<button className="btn fb-btn" onClick={() => {
						this.props.alert.show(<>Facebook login is no longer available.<br />Please reset your password
								to log in, using the same email address as your facebook account.</>,
							{ timeout: 0 });
					}}>
						<FontAwesomeIcon icon={faFacebookF} className="facebook-icon" />
						Log in using Facebook
					</button>
					{signupButton}
					{this.props.children}
				</div>
			</div>
		</div>;
	}
}

export default withAlert()(LoginBox);