import React, { Component } from "react";
import ApiFunc from "./ApiCalls/ApiFunc";
import Content from "./Content";

class Contents extends Component {
	constructor (props) {
		super(props);

		this.state = {
			contents: {}
		};
	}

	fetchContent () {
		const path = "path=" + this.getPath();

		ApiFunc(`published-contents/public/by-path?${path}`)
			.then(response => response.json())
			.then(data => this.setState({
				contents: data.published_contents
			}))
			.catch(error => {
				// alert("Error when fetching content: ", error);
				// console.error("Error fetching content", error);
			});
	}

	componentDidMount () {
		this.fetchContent();
	}

	getPath () {
		let path = location.pathname;
		return (path.length && path[0] === "/") ? path.slice(1) : path;
	}

	render () {
		const { contents } = this.state;
		let topContent = [], bottomContent = [];

		if (contents && contents.length > 0) {
			topContent = contents.filter(content => content.position > 0);
			bottomContent = contents.filter(content => content.position < 1);
		}

		const topContents = topContent.map((content, i) => <Content key={i} html={content.html} css={content.css} />);
		const bottomContents = bottomContent.map((content, i) => <Content key={i} html={content.html}
																		  css={content.css} />);

		return (
			<>
				{topContents}
				{this.props.children}
				{bottomContents}
			</>
		);
	}
}

export default Contents;