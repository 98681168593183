import React, { Component } from "react";
import { lookupOptionText } from "../utilities/TextLookups";
import { stripSpaces } from "../utilities/stringFuncs";

class RadioOptions extends Component {
	render () {
		const options = this.props.options.map(option => {
			return (
				<div key={option} className={this.props.name + '-option'}>
					<input type="radio"
						   id={stripSpaces(this.props.name + '-' + option)}
						   name={this.props.name}
						   value={option}
						   checked={this.props.value === option}
						   onChange={this.props.onChange}
					/>
					<label htmlFor={stripSpaces(this.props.name + '-' + option)}>
						<div className="radio-button" />
						{lookupOptionText(this.props.formType, this.props.name, option)}
					</label>
				</div>
			);
		});

		return (
			<div className={this.props.name}>
				{options}
			</div>
		);
	}
}

export default RadioOptions;