import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// This is the earning progress component shown near the header
class EarningProgress extends Component {
	render () {
		return (
			<header className="earning-progress">
				<img className="woman-phone"
					 src="https://cashback-public.s3.eu-west-2.amazonaws.com/homepage/mobilewoman.svg"
					 alt="Woman earning money on her phone" />
				<div className="layout">
					<p className="progress-text">
						Earning Progress <span
						className="percentage">{(this.props.percentageComplete ?? 0) + "%"}</span>
					</p>
					<div className="progress-bar">
						<div className="progress-bar-inner"
							 style={{ "width": "max(30px, " + (this.props.percentageComplete ?? 0) + "%)" }}>
							<img className="progress-icon"
								 src="https://cashback-public.s3.eu-west-2.amazonaws.com/logo/logo-white.svg"
								 alt="Cashback"
							/>
						</div>
						<Link to="/earnings" aria-label="Earnings" className="help-icon-link">
							<div className="help-icon" />
						</Link>
					</div>
				</div>
				<img className="man-laptop"
					 src="https://cashback-public.s3.eu-west-2.amazonaws.com/icons/sitting-laptop-man.svg"
					 alt="Man earning money on laptop" />
			</header>
		);
	}
}

EarningProgress.propTypes = {
	percentageComplete: PropTypes.number
};

export default EarningProgress;