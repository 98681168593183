import React, { Component } from "react";
import Main from "../Main";
import { Link } from "react-router-dom";
import { addCanonical } from "../../utilities/addTagsToHead";

class EmptyLander extends Component {
	componentDidMount () {
		addCanonical();
	}

	render () {
		return (
			<Main hideFooter={this.props.hideFooter}>
				{!this.props.hideContinueButton && (
					<div className="center">
						<Link to="/tasks" className="btn tertiary-btn">Continue</Link>
					</div>
				)}
			</Main>
		);
	}
}

export default EmptyLander;