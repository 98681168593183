import React, { Component } from "react";
import { getToken, getUserUuid } from "../utilities/tracking";
import LoginBox from "./LoginBox";
import { replaceVariables } from "../utilities/TextLookups";

class HelpdeskLoader extends Component {
	constructor () {
		super();

		this.state = {
			showModal: null
		};

		this.handleClick = this.handleClick.bind(this);
		this.showModal = this.showModal.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	handleClose () {
		this.setState({
			showModal: null
		});
	}

	showModal (modalName) {
		this.setState({
			showModal: modalName
		});
	}

	handleClick (e) {
		e.preventDefault();
		const uuid = getUserUuid();
		const token = getToken();

		if (uuid && token) {
			document.location = replaceVariables("https://helpdesk.cashback.co.uk/?u=%%USERUUID%%&a=%%TOKEN%%");
			return;
		}

		this.showModal("login");
	}

	render () {
		let element = <a href="https://helpdesk.cashback.co.uk" className="" onClick={this.handleClick}>
			Help Desk
		</a>;
		if (this.props.type === "button") {
			element = <button className="btn primary-btn mb-1" onClick={this.handleClick}>
				Log a ticket
			</button>;
		}

		return (
			<>
				<LoginBox show={this.state.showModal === "login"}
						  handleClose={this.handleClose}
						  switchModal={this.showModal}
						  hideSignUp={true}
						  redirectTo="https://helpdesk.cashback.co.uk/?u=%%USERUUID%%&a=%%TOKEN%%">
					<details id="cant-login">
						<summary>Can't log in?</summary>
						<p>Please email <a href="mailto:members@cashback.co.uk">members@cashback.co.uk</a></p>
					</details>
				</LoginBox>
				{element}
			</>
		);
	}
}

export default HelpdeskLoader;