import React, { Component } from "react";
import ReactHtmlParser from "html-react-parser";
import { camelCase } from "../utilities/stringFuncs";

class Content extends Component {
	render () {
		let elements = ReactHtmlParser(this.props.html, {
			replace: domNode => {
				if (domNode.type === "script") {
					let script = document.createElement("script");

					for (const attr in domNode.attribs) {
						if(attr.slice(0,5) === 'data-'){
							const dataAttr = camelCase(attr.slice(5));
							script.dataset[dataAttr] = domNode.attribs[attr];
							continue;
						}

						if(attr === 'async'){
							script.async = true;
							continue;
						}

						script[attr] = domNode.attribs[attr];
					}

					if (domNode?.children[0]?.data) {
						script.innerHTML = domNode.children[0].data;
					}

					document.head.appendChild(script);

					return <></>;
				}
			}
		});

		const styles = this.props.css ? <style>{this.props.css}</style> : <></>;

		return (
			<>
				{elements}
				{styles}
			</>
		);
	}
}

export default Content;