import ApiFunc from "./ApiFunc";
import { getToken } from "../../utilities/tracking";

export function sendResetPasswordEmail (email, alert) {
	ApiFunc("user/password-reset", "POST", { email: email })
		.then(response => response.json())
		.then(data => {
			if (data.password_reset_sent) {
				let message = "An email has been sent to " + email + ", with instructions on how to" +
					" change your password.";

				if (!getToken()) {
					message = "If a user account with the email address of " + email + " exists, an email will be" +
						" sent now.  Please check your mailbox for instructions on how to change your password.";
				}

				alert.success(message,
					{
						timeout: 0
					});
				return;
			}

			alert.error("There was an error when trying to send your password reset email.  Please" +
				" contact the helpdesk.",
				{
					timeout: 0
				});
		});
}