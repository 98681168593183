import React, { Component } from "react";
import PropTypes from "prop-types";

class QuestionSpend extends Component {
	render () {
		return (
			<>
				<p className="survey-question">{"Q" + (this.props.questionNumber || 1) +
					" of " + (this.props.totalQuestions || 3) + ": When you cash-out," +
					" how do you think you'll spend it?"}</p>

				<label htmlFor="box1">
					<input type="checkbox"
						   name="cbuk_survey_spend"
						   value="cbuk_survey_spend_bills"
						   id="box1"
						   onClick={this.props.onClick}
					/>
					Bills
				</label>

				<label htmlFor="box2">
					<input type="checkbox"
						   name="cbuk_survey_spend"
						   value="cbuk_survey_spend_food"
						   id="box2"
						   onClick={this.props.onClick}
					/>
					Food
				</label>

				<label htmlFor="box3">
					<input type="checkbox"
						   name="cbuk_survey_spend"
						   value="cbuk_survey_spend_debt"
						   id="box3"
						   onClick={this.props.onClick}
					/>
					Paying off debt
				</label>

				<label htmlFor="box4">
					<input type="checkbox"
						   name="cbuk_survey_spend"
						   value="cbuk_survey_spend_holiday"
						   id="box4"
						   onClick={this.props.onClick}
					/>
					Holiday
				</label>

				<label htmlFor="box5">
					<input type="checkbox"
						   name="cbuk_survey_spend"
						   value="cbuk_survey_spend_shopping"
						   id="box5"
						   onClick={this.props.onClick}
					/>
					Shopping spree
				</label>

				<label htmlFor="box6">
					<input type="checkbox"
						   name="cbuk_survey_spend"
						   value="cbuk_survey_spend_presents"
						   id="box6"
						   onClick={this.props.onClick}
					/>
					Christmas/Birthday presents
				</label>

				<label htmlFor="box7">
					<input type="checkbox"
						   name="cbuk_survey_spend"
						   value="cbuk_survey_spend_wedding"
						   id="box7"
						   onClick={this.props.onClick}
					/>
					Wedding
				</label>

				<label htmlFor="box8">
					<input type="checkbox"
						   name="cbuk_survey_spend"
						   value="cbuk_survey_spend_yourself"
						   id="box8"
						   onClick={this.props.onClick}
					/>
					Treat for yourself
				</label>

				<label htmlFor="box9">
					<input type="checkbox"
						   name="cbuk_survey_spend"
						   value="cbuk_survey_spend_kids"
						   id="box9"
						   onClick={this.props.onClick}
					/>
					Treat for the kids
				</label>

				<label htmlFor="box10">
					<input type="checkbox"
						   name="cbuk_survey_spend"
						   value="cbuk_survey_spend_other"
						   id="box10"
						   onClick={this.props.onClick}
					/>
					Other
				</label>
			</>
		);
	}
}

QuestionSpend.propTypes = {
	questionNumber: PropTypes.number,
	totalQuestions: PropTypes.number,
	onClick: PropTypes.func.isRequired
};

export default QuestionSpend;