import React from "react";
import { Link } from "react-router-dom";

export default function ErrorCard (props) {

	let card;

	if (props.message) {
		card = (
			<div className="card">
				<div className="card-content">
					<h3>Error: {props.message}</h3>
				</div>
			</div>
		);
	} else {
		card = (
			<p className='error'>
				Please <Link to='/signup'>Sign Up</Link> or <Link to='/login'>Login</Link> to see this content
			</p>
		);
	}

	return card;
}