import React, { Component } from "react";
import SignUpBox from "./SignUpBox";
import LoginBox from "./LoginBox";
import Header from "./Header";
import Footer from "./Footer";
import Contents from "./Contents";
import PropTypes from "prop-types";

class Main extends Component {
	constructor (props) {
		super(props);
		this.state = {
			expanded1: false,
			expanded2: false,
			showModal: null,
			trackModalAs: null
		};

		this.showModal = this.showModal.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	showModal (modalName, trackModalAs) {
		this.setState({
			showModal: modalName,
			trackModalAs: trackModalAs
		});
	}

	handleClose () {
		this.setState({
			showModal: null,
			trackModalAs: null
		});
	}

	render () {
		return (
			<>
				<SignUpBox show={this.state.showModal === "signup"}
						   trackAs={this.state.trackModalAs}
						   switchModal={this.showModal}
						   handleClose={this.handleClose} />
				<LoginBox show={this.state.showModal === "login"}
						  switchModal={this.showModal}
						  handleClose={this.handleClose} />
				<Header className={this.props.headerClassName}
						showModal={this.showModal}
						showH1={this.props.showH1} />
				<div className={"main-container " + this.props.className}>
					<Contents>
						{this.props.children}
					</Contents>
				</div>
				{!this.props.hideFooter && <Footer className={this.props.footerClassName}
						showModal={this.showModal} />}
			</>
		);
	}
}

Main.propTypes = {
	showH1: PropTypes.bool,
	hideFooter: PropTypes.bool,
};

export default Main;