import { serviceUrls } from "../../Routes";
import { getToken } from "../../utilities/tracking";
import * as Sentry from "@sentry/react";

export default function ApiFunc (section, postType = "GET", body = null) {

	if (!window.navigator.onLine) {
		alert("You are currently offline. Please try again when you are connected to the internet");
		return new Promise(() => {});
	}

	const token = getToken();

	let requestOptions = {
		method: postType,
		withCredentials: true,
		headers: new Headers({
			"Authorization": "Bearer " + token,
			"Content-Type": "application/x-www-form-urlencoded"
		})
	};

	if (postType === "POST" || postType === "PATCH") {
		if (body !== null) {
			requestOptions.body = JSON.stringify(body);
		}

		if (token === null) {
			requestOptions.headers = new Headers({
				"Content-Type": "application/json"
			});
		} else {
			requestOptions.headers = new Headers({
				"Authorization": "Bearer " + token,
				"Content-Type": "application/json"
			});
		}
	}

	return fetch(`${serviceUrls().serverUrl}/${section}`, requestOptions)
		.then(response => {
			var statusCodeInt = parseInt(response.status);
			if (statusCodeInt !== 200 && statusCodeInt !== 404){
				response.text().then(errorMessage => {
					if (Math.random() < 0.05) {
						Sentry.captureException(response.status + "\n" + response.url + "\n" + errorMessage);
					}
				});
				throw response;
			}
			return response;
		});
}
