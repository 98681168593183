import React, { Component } from "react";
import TrustPilotSlider from "../TrustPilotSlider";
import SignUpBox from "../SignUpBox";
import LoginBox from "../LoginBox";
import { Link } from "react-router-dom";
// import BrandsSlider from "../BrandsSlider";
import ApiFunc from "../ApiCalls/ApiFunc";
import { formatMoney } from "../../utilities/stringFuncs";
import { addCanonical } from "../../utilities/addTagsToHead";
import { withRouter } from "react-router";
import { getParamFromUrl } from "../../utilities/urlUtilities";
import HelpdeskLoader from "../HelpdeskLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import SignUpForm from "../SignUpForm";
import { checkGeo } from "../ApiCalls/geo";
import Footer from "../Footer";
import TrustPilotMicroStar from "../TrustPilotMicroStar";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Menu from "../Menu";
import { getCookie } from "../../utilities/cookies";
import { local } from "../../utilities/storage";
import VideoBox from "../VideoBox";

// noinspection HtmlUnknownAnchorTarget,JSClassNamingConvention
class Lander202304 extends Component {
	constructor (props) {
		super(props);

		let modal = null;
		let signupCta = null;
		if (getParamFromUrl("signauto", true) === "1") {
			modal = "signup";
			signupCta = "externalCTA";
		}

		this.state = {
			showVideo: false,
			showModal: modal,
			signupCta: signupCta,
			menuExpanded: false,
			geoOk: null,
			totalPaid: 37192500
		};

		this.showModal = this.showModal.bind(this);
		this.showVideo = this.showVideo.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleToggleMenu = this.handleToggleMenu.bind(this);
	}

	showModal (modalName, signupCta) {
		this.setState({
			showModal: modalName,
			signupCta: signupCta
		});
	}

	showVideo (show) {
		this.setState({ showVideo: show });
	}

	handleClose () {
		this.setState({
			showModal: null,
			showVideo: false
		});
	}

	handleToggleMenu () {
		this.setState(prevState => ({
			menuExpanded: !prevState.menuExpanded
		}));
	}

	fetchPaidTotal (cached = true) {
		ApiFunc(`payment-stats/total-payout?cached=${cached}`)
			.then(response => response.json())
			.then(data => {
				if (data?.total_paid) {
					this.setState({ totalPaid: data.total_paid });
				}
			})
			.catch(error => {
				console.error("Fetch total paid error", error);
			});
	}

	componentDidMount () {
		addCanonical();
		this.fetchPaidTotal();

		const geoOk = local.getItem("okoeg");
		if (geoOk !== null) {
			this.setState({ geoOk });
		} else {
			checkGeo()
				.then(geoOk => {
					this.setState({ geoOk });
					local.setItem("okoeg", geoOk);
				})
				.catch(error => {
					console.error("Error checking geo", error);
				});
		}
	}

	render () {
		const { totalPaid } = this.state;

		// const hideTakeALookSources = ["tiktok"];
		// let hideTakeALook = "";
		// if (hideTakeALookSources.includes(session.getItem("source"))) {
		// 	hideTakeALook = " hide";
		// }

		let partnerBrand;
		if (this.props.partnerBrand) {
			let brandImage;
			switch (this.props.partnerBrand) {
				case "researchTribe":
					brandImage =
						<img src="https://cashback-public.s3.eu-west-2.amazonaws.com/homepage/research-tribe-logo.png"
							 className="partner-logo"
							 alt="Research Tribe"
							 width="174"
							 height="128" />;
					break;

				case "opinionGroups":
					brandImage =
						<img src="https://cashback-public.s3.eu-west-2.amazonaws.com/homepage/opinion-groups-logo.png"
							 className="partner-logo"
							 alt="Opinion Groups"
							 width="128"
							 height="125" />;
					break;

				case "mySideGig":
					brandImage =
						<img src="https://cashback-public.s3.eu-west-2.amazonaws.com/homepage/my-side-gig.png"
							 className="partner-logo"
							 alt="My Side Gig"
							 width="128"
							 height="125" />;
					break;

				case "emmasDiary":
					brandImage =
						<img src="https://cashback-public.s3.eu-west-2.amazonaws.com/homepage/emmas-diary.png"
							 className="partner-logo"
							 alt="Emmas Diary"
							 width="336"
							 height="152" />;
					break;
			}
			partnerBrand = <>
				<span className="in-partnership">in partnership with</span>
				{brandImage}
			</>;
		}

		let welcomeReward;
		switch (getCookie("welcome_bonus_uuid")) {
			case "3212064b-1ee2eb76-b7a7-63ce-925e-444230303035":
			case "3212064b-1efcb476-3498-6888-941e-444230303035":
				welcomeReward = "£15";
				break;
			case "3212064b-1ed2eb39-99ba-605e-840e-444230303035":
			case "3212064b-1ed2eb76-b6a7-62ce-915e-444230303035":
				welcomeReward = "£10";
				break;
			default:
				welcomeReward = "£5";
		}

		return (
			<>
				<SignUpBox show={this.state.showModal === "signup"}
						   handleClose={this.handleClose}
						   switchModal={this.showModal}
						   trackAs={this.state.signupCta}
						   referredByUserUuid={this.props.referredByUserUuid} />
				<LoginBox show={this.state.showModal === "login"}
						  handleClose={this.handleClose}
						  switchModal={this.showModal}
						  redirectTo="/tasks" />
				<VideoBox
					videoId="wV_r5khQmMk"
					show={this.state.showVideo}
					handleClose={this.handleClose} />


				<main id="lander202304">
					<div className="lander-inner">
						<header>
							<div className="left">
								<Link to="/" className="main_logo"><img
									src="https://cashback-public.s3.eu-west-2.amazonaws.com/logo/cashback-and-logo.png"
									className="logo-img"
									alt="Home"
									title="Home"
									width="654"
									height="140"
								/>
									{partnerBrand}
								</Link>
							</div>
							<TrustPilotMicroStar />
							<div className="links secondary">
								{this.props.hidePreview || <Link
									to="/tasks/level-2?utm_content=take-a-look-new"
									className="demo">Take a look</Link>}
								<a href="https://blog.cashback.co.uk">Blog</a>
								<HelpdeskLoader />
							</div>
							<div className="links primary">
								<a href="#signup" className="btn-text">Sign up</a>
								<button className="btn-text"
										onClick={() => {this.showModal("login", "cta2new");}}>
									Log in
								</button>
								<div id="expand-menu"
									 tabIndex={0}
									 aria-label="Toggle menu"
									 role="button"
									 onKeyUp={e => {
										 if (e.key === "Enter" || e.key === "Escape") {
											 this.handleToggleMenu();
										 }
									 }}
									 onClick={this.handleToggleMenu}>
									<FontAwesomeIcon icon={faBars} />
								</div>
							</div>
							<Menu
								className=""
								show={this.state.menuExpanded}
								handleClose={this.handleToggleMenu}
								showModal={(modalType) => {
									this.showModal(modalType);
								}} />
						</header>

						<section className="top light">
							<div className="hero">
								<div className="title-layout">
									{this.props.mainTitleJsx ||
										<h1 className="main-title">
											<strong>Earn Cashback</strong> in your spare time!</h1>}
									{this.props.mainSubTitleJsx ||
										<h2 className="main-subtitle">
											By completing free trials, sign ups, and online tasks
										</h2>}
									<a href="#signup" className="lander-button">
										<p>Join now for <strong>FREE</strong></p>
									</a>

									<a href="#signup" className="welcome-bonus">
										<strong>FREE {this.props.strikethroughBonus &&
											<span className="not-this-bonus">£5</span>}
											{welcomeReward}</strong>
										<p>welcome bonus</p>
									</a>
								</div>

								{this.props.altPeopleImg &&
									<div
										className={"real-people-container real-people-container-" + this.props.altPeopleImg}>
										<img
											src="https://cashback-public.s3.eu-west-2.amazonaws.com/homepage/real-people.jpg"
											alt="People"
											className={"real-people real-people-" + this.props.altPeopleImg} />
									</div>}
								{!this.props.altPeopleImg &&
									<div className="people">
										<img
											src="https://cashback-public.s3.eu-west-2.amazonaws.com/lander-2023-04/lady-phone.png"
											alt="Phone lady"
											className="lady"
											width="297"
											height="400"
										/>
										<img
											src="https://cashback-public.s3.eu-west-2.amazonaws.com/lander-2023-04/man-laptop.png"
											alt="Laptop man"
											className="man"
											width="390"
											height="600"
										/>
									</div>
								}

								{!this.props.largeMoneyFacts && <img
									src="https://cashback-public.s3.eu-west-2.amazonaws.com/lander-2023-04/MoneyFacts-logo-white-text.png"
									className="mfc-logo"
									alt="Highly commended Moneyfacts Consumer Awards 2023 for Cashback Site of the Year" />}

							</div>

							{/*<h2 className="brands-title">*/}
							{/*	<strong>Brands</strong> we work with:*/}
							{/*</h2>*/}
							{/*<BrandsSlider*/}
							{/*	hideTitle={true}*/}
							{/*	autoplay="0"*/}
							{/*	animationDuration="4000"*/}
							{/*	animationTimingFunc="linear"*/}
							{/*	perView={document.documentElement.clientWidth > 800 ? 5 : 2}*/}
							{/*/>*/}
						</section>


						<section className="paid">
							<p className="right">We've now paid over</p>
							<p className="amount">{formatMoney(totalPaid, "GBP", true)}</p>
							<p className="left">to our members</p>
						</section>

						{this.props.topSignUpForm && <section className="sign-up">
							<a name="signup" />
							<h2>Join today for FREE and start earning!</h2>
							<SignUpForm
								className="signup-form"
								disabled={!this.state.geoOk}
								referredByUserUuid={this.props.referredByUserUuid}
								trackAs={this.state.signupCta}
								submitText={<p>Join now for <strong>FREE</strong></p>}
							>
								<div className="signup-terms_box">
									<p className="signup-terms">The information you provide to us, Submission
										Technology,
										Ltd.,
										is used to create a Cashback account for you so you can track your progress
										and we can pay you when necessary. By registering and entering you confirm
										that you have read and agree to our <a href="/terms-and-conditions">Terms and
											Conditions</a> &amp; <a href="/privacy-notice">Privacy Notice</a> which
										governs how your information will be processed.
										<br />UK RESIDENTS OVER 18 ONLY</p>
								</div>
							</SignUpForm>

							<div className="buttons-container">
								<p className="signup-login">Already have an account?
									<a href="/login" onClick={e => {
										e.preventDefault();
										this.showModal("login");
									}}
									   className="login-link">Log in</a>
								</p>
							</div>
						</section>}

						{this.props.largeMoneyFacts && <section className="large-mfc-container">
							<img
								src="https://cashback-public.s3.eu-west-2.amazonaws.com/lander-2023-04/MoneyFacts-logo-white-text.png"
								className="mfc-logo-large"
								alt="Highly commended Moneyfacts Consumer Awards 2023 for Cashback Site of the Year" />
						</section>}

						<section className="show-me-how">
							{!this.props.hideVideo && (
								<>
									<h2 className="show-me">Show me how it works!</h2>

									<div className="video-container">
										{/*<img src="https://cashback-public.s3.eu-west-2.amazonaws.com/icons/circle-arrow.svg"*/}
										{/*	 alt="Play video"*/}
										{/*	 onClick={() => this.showVideo(true)}*/}
										{/*/>*/}
										<iframe src="https://www.youtube.com/embed/wV_r5khQmMk"
												allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
												allowFullScreen></iframe>
									</div>
								</>
							)}

							<ol className="steps-list">
								<li className="step">
									<p className="num">1</p>
									<img
										src="https://cashback-public.s3.eu-west-2.amazonaws.com/lander-2023-04/join.png"
										alt="Join for free" />
									<h3>Join for FREE</h3>
									<p>Sign up now, it's easy <br />and free to join.</p>
								</li>
								<li className="step">
									<p className="num">2</p>
									<img
										src="https://cashback-public.s3.eu-west-2.amazonaws.com/lander-2023-04/tasks.png"
										alt="Complete tasks" />
									<h3>Complete Tasks</h3>
									<p>Follow instructions to complete all of the tasks.</p>
								</li>
								<li className="step">
									<p className="num">3</p>
									<img
										src="https://cashback-public.s3.eu-west-2.amazonaws.com/lander-2023-04/get-paid.png"
										alt="Get paid" />
									<h3>{this.props.safeCopy ? "Get Rewarded" : "Get Paid"}</h3>
									<p>{this.props.safeCopy ? "Once all of your tasks have been updated it's time to" +
										" get rewarded!" : "Once" +
										" all of your tasks have been confirmed you" +
										" will be paid via Bank Transfer or PayPal."}</p>
								</li>
							</ol>

							<div className="buttons-layout">
								<a href="#signup" className="lander-button">
									<p>Join now for <strong>FREE</strong></p>
								</a>
								{this.props.hidePreview ||
									<Link to="/tasks/level-2?utm_content=take-a-look-new"
										  className="outline-button">
										Take a look
									</Link>}
							</div>
						</section>


						<section className="reviews light">
							<div className="faq-layout">
								<article>
									<h3>How does it work?</h3>
									<h4>It's free &amp; easy to join</h4>
									<p>
										{this.props.safeCopy ? "When you complete one of our many tasks we earn a" +
											" commission from advertisers, which we share with you. Just follow our" +
											" simple instructions and you'll be rewarded for every single task, once" +
											" you have completed 15 levels." :
											"When you complete one of our many tasks we earn a" +
											" commission from advertisers, which we share with you." +
											" Just follow our simple instructions and you'll be able to profit from every" +
											" single task, once you have successfully completed 15 levels."}
									</p>
								</article>

								{!this.props.safeCopy && (
									<article>
										<h3>How much can I earn?</h3>
										<h4>It really depends....</h4>
										<p>Let's keep this realistic, it's not going to be £100's daily. On average a
											member earns just over £150, highest ever payout is over £400.
											Now who wouldn't want that? We also pay our members 3 times a week via Bank
											Transfer or PayPal. <br />
											<Link to="/earnings-disclaimer">Earnings Disclaimer</Link>
										</p>
									</article>
								)}
							</div>

							{!this.props.hideReviews && (
								<>
									<h2 className="reviews-title">Our Reviews</h2>
									<div className="slider-container">
										<TrustPilotSlider />
									</div>
								</>
							)}

							{this.props.showBottomCta && (
								<div className="buttons-layout">
									<a href="#signup" className="lander-button">
										<p>Join now for <strong>FREE</strong></p>
									</a>
								</div>
							)}
						</section>


						{!this.props.topSignUpForm && <section className="sign-up">
							<a name="signup" />
							<h2>Join today for FREE and start earning!</h2>
							<SignUpForm
								className="signup-form"
								disabled={!this.state.geoOk}
								referredByUserUuid={this.props.referredByUserUuid}
								trackAs={this.state.signupCta}
								submitText={<p>Join now for <strong>FREE</strong></p>}
							>
								<div className="signup-terms_box">
									<p className="signup-terms">The information you provide to us, Submission
										Technology,
										Ltd.,
										is used to create a Cashback account for you so you can track your progress
										and we can pay you when necessary. By registering and entering you confirm
										that you have read and agree to our <a href="/terms-and-conditions">Terms and
											Conditions</a> &amp; <a href="/privacy-notice">Privacy Notice</a> which
										governs how your information will be processed.
										<br />UK RESIDENTS OVER 18 ONLY</p>
								</div>
							</SignUpForm>

							<div className="buttons-container">
								<p className="signup-login">Already have an account?
									<a href="/login" onClick={e => {
										e.preventDefault();
										this.showModal("login");
									}}
									   className="login-link">Log in</a>
								</p>
							</div>
						</section>}

						<Footer />
					</div>
				</main>
			</>
		);
	}
}

Lander202304.propTypes = {
	mainTitleJsx: PropTypes.element,
	mainSubTitleJsx: PropTypes.element,
	hidePreview: PropTypes.bool,
	referredByUserUuid: PropTypes.string,
	partnerBrand: PropTypes.string,
	safeCopy: PropTypes.bool,
	strikethroughBonus: PropTypes.bool
};

export default withRouter(Lander202304);