import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import Contents from "../Contents";
import RungsHeader from "../RungsHeader";
import { getUserUuid } from "../../utilities/tracking";
import ApiFunc from "../ApiCalls/ApiFunc";
import EarningProgress from "../EarningProgress";
import { getProgressPercentage } from "../RungHeading";
import { addCanonical } from "../../utilities/addTagsToHead";
import { local } from "../../utilities/storage";
import SignUpBox from "../SignUpBox";
import LoginBox from "../LoginBox";
import { getCookie } from "../../utilities/cookies";

class Finished extends Component {
	constructor (props) {
		super(props);
		this.state = {
			rungs: [],
			showModal: null,
		};
		document.title = "Great work! | Cashback";

		this.showModal = this.showModal.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	showModal (modalName) {
		this.setState({ showModal: modalName });
	}

	handleClose () {
		this.setState({
			showModal: null
		});
	}

	componentDidMount () {
		addCanonical();
		this.fetchRungs();
	}

	fetchRungs () {
		if (getCookie("held_admin") && getCookie("impersonate_user_uuid") === undefined) {
			return;
		}

		const userUuid = getUserUuid();
		const trackUuid = local.getItem("track_uuid") ?? null;

		if (!userUuid) {
			this.showModal("login");
			return;
		}

		ApiFunc(`ladder/rungs/user/${userUuid}?track_uuid=${trackUuid}`)
			.then(response => response.json())
			.then(data => {
				if (data.rungs) {
					this.setState({ rungs: data.rungs });
				}
			})
			.catch(error => {
				alert("Error when fetching rungs: ", error);
			});
	}

	render () {
		const { rungs } = this.state;

		return (
			<div className="finished-container">
				<SignUpBox show={this.state.showModal === "signup"}
						   switchModal={this.showModal}
						   handleClose={this.handleClose} />
				<LoginBox show={this.state.showModal === "login"}
						  switchModal={this.showModal}
						  handleClose={this.handleClose} />
				<Header showModal={this.showModal} showH1={false} />
				<EarningProgress percentageComplete={getProgressPercentage(rungs)} />
				<RungsHeader rungs={rungs} className="rungs-header-finished" />
				<main className="finished-page">
					<Contents>
						<img src="https://cashback-public.s3.eu-west-2.amazonaws.com/icons/cashback_finish_icon.png"
							 alt="Finished Trophy"
							 width="200"
							 height="200"
						/>
						<h1><i>Great work,</i> you've unlocked all your Levels!</h1>
						<p>What's next? Once all 15 Levels are <i>Green</i>, you can cash out!</p>
						<p>Make sure to update your secure <Link to="/payment-details">payment details</Link></p>
					</Contents>
				</main>
				<Footer />
			</div>
		);
	}
}

export default Finished;