import React, { Component } from "react";
import TrustPilot from "../TrustPilot";
import TrustPilotSlider from "../TrustPilotSlider";
import VideoBox from "../VideoBox";
import SignUpBox from "../SignUpBox";
import LoginBox from "../LoginBox";
import Header from "../Header";
import { Link } from "react-router-dom";
import BrandsSlider from "../BrandsSlider";
import ApiFunc from "../ApiCalls/ApiFunc";
import { formatMoney } from "../../utilities/stringFuncs";
import { addCanonical } from "../../utilities/addTagsToHead";
import { getToken } from "../../utilities/tracking";
import { withRouter } from "react-router";
import { getParamFromUrl } from "../../utilities/urlUtilities";
import HelpdeskLoader from "../HelpdeskLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { getCookie } from "../../utilities/cookies";

class Lander extends Component {
	constructor (props) {
		super(props);

		let modal = null;
		let signupCta = null;
		if (getParamFromUrl("signauto", true) === "1") {
			modal = "signup";
			signupCta = "externalCTA";
		}

		this.state = {
			showVideo: false,
			showModal: modal,
			signupCta: signupCta,
			totalPaid: 37192500
		};

		this.showModal = this.showModal.bind(this);
		this.showVideo = this.showVideo.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	showModal (modalName, signupCta) {
		this.setState({
			showModal: modalName,
			signupCta: signupCta
		});
	}

	showVideo (show) {
		this.setState({ showVideo: show });
	}

	handleClose () {
		this.setState({
			showModal: null,
			showVideo: false
		});
	}

	fetchPaidTotal (cached = true) {
		ApiFunc(`payment-stats/total-payout?cached=${cached}`)
			.then(response => response.json())
			.then(data => {
				if (data.total_paid) {
					this.setState({ totalPaid: data.total_paid });
				}
			})
			.catch(error => {
				console.error("Fetch total paid error", error);
			});
	}

	componentDidMount () {
		addCanonical();
		this.fetchPaidTotal();
		this.scrollbarWidth();
	}

	scrollbarWidth () {
		let scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
		if (scrollbarWidth > 0) {
			document.documentElement.style.setProperty(
				"--scrollbarWidth",
				scrollbarWidth + "px"
			);
		}
	}

	render () {
		const { totalPaid } = this.state;
		const token = getToken();
		const loggedIn = (token !== null);
		if (loggedIn) {
			this.props.history.push("/");
		}

		// const hideTakeALookSources = ["tiktok"];
		// let hideTakeALook = "";
		// if (hideTakeALookSources.includes(session.getItem("source"))) {
		// 	hideTakeALook = " hide";
		// }

		return (
			<>
				<SignUpBox show={this.state.showModal === "signup"}
						   handleClose={this.handleClose}
						   switchModal={this.showModal}
						   trackAs={this.state.signupCta}
						   referredByUserUuid={this.props.referredByUserUuid} />
				<LoginBox show={this.state.showModal === "login"}
						  handleClose={this.handleClose}
						  switchModal={this.showModal}
						  redirectTo="/tasks" />
				<Header showModal={this.showModal}
						showH1={false}
						forLander={true}
						partnerBrand={this.props.partnerBrand}
						className="lander-header" />

				<VideoBox
					videoId="5mL_eyXpCRI"
					show={this.state.showVideo}
					handleClose={this.handleClose} />

				<main id="lander202205">
					<div className="lander-inner">
						<section className="section1">
							<div className="hero">
								<div className="people-img" />
								<div className="hp-title-container">
									{this.props.mainTitleJsx ||
										<h1 className="main-title">Earn Cashback <span className="smaller">in your spare time!</span>
										</h1>}
									{this.props.mainSubTitleJsx ||
										<h2 className="main-subtitle">By completing free trials, sign ups, and online
										tasks</h2>}
								</div>

								<div className="buttons-container">
									<div className="welcome-bonus">
										<p>Join today for a</p>
										<p><span
											className="huge">{getCookie("welcome_bonus_uuid") ? "£10" : "£5"}</span>welcome
											bonus</p>
									</div>
									<button className="lander-button1"
											onClick={() => {this.showModal("signup", "cta2new");}}>
										<p>JOIN now for FREE!</p>
										<FontAwesomeIcon className="chevron" icon={faChevronRight} />
									</button>
									{this.props.hidePreview || <Link to="/tasks/level-2?utm_content=take-a-look-new"
																	 className="take-a-look">Take a look</Link>}
								</div>
							</div>

							<div className="reviews-container">
								<h2 className="reviews-title">Check out our <strong>latest reviews...</strong></h2>
								<div className="hp-trustpilot-widget">
									<TrustPilot theme="light" />
								</div>
								<div className="slider-container">
									<TrustPilotSlider />
								</div>
							</div>

						</section>


						<section className="section2">
							<div className="we-paid">
								<p className="we-paid-text">We've now paid over</p>
								<p className="we-paid-amount">{formatMoney(totalPaid, "GBP", true)}</p>
								<p className="we-paid-text">to our members</p>

								<div className="video-layout">
									<p className="show-me">Show me
										<wbr />
										&nbsp;how&nbsp;it&nbsp;works!
									</p>

									<div className="video-container">
										<button className="youtube" aria-label="Play video"
												onClick={() => this.showVideo(true)} />
										<p className="press-to-play">Press to play</p>
									</div>

									<button className="lander-button1"
											onClick={() => {this.showModal("signup", "cta3new");}}>
										<p>JOIN now for FREE!</p>
										<FontAwesomeIcon className="chevron" icon={faChevronRight} />
									</button>
								</div>
							</div>
						</section>


						<section className="section3">
							<h2 className="steps-title">Follow our <span className="simple-steps">3 simple steps</span>
							</h2>
							<div className="steps-container">
								<div className="ribbon">
									<ol>
										<li className="step1"><p className="num">1</p>Sign up now, <br />it's easy
											and <br />free to join
										</li>
										<li className="step2"><p className="num">2</p>Follow instructions <br />to
											complete all <br />of the tasks
										</li>
										<li className="step3"><p className="num">3</p>Once all of your tasks <br />have
											been confirmed <br />you will be paid via <br />Bank Transfer or PayPal
										</li>
									</ol>
								</div>
							</div>

							<button className="lander-button1" onClick={() => {
								this.showModal("signup", "cta4new");
							}}>
								<p>JOIN now for FREE!</p>
								<FontAwesomeIcon className="chevron" icon={faChevronRight} />
							</button>
						</section>


						<section className="section4">
							<div className="faq-container">
								<article>
									<h2>How does it work?</h2>
									<h3>It's free &amp; easy to join</h3>
									<p>When you complete one of our many tasks we earn a commission from
										advertisers, which we share with you.
										Just follow our simple instructions and you'll be able to profit from every
										single task, once you have successfully completed 15 levels.</p>
								</article>

								<article>
									<h2>How much can I earn?</h2>
									<h3>It really depends....</h3>
									<p>Let's keep this realistic, it's not going to be £100's daily. On average a
										member earns just over £150, highest ever payout is over £400.
										Now who wouldn't want that? We also pay our members 3 times a week via Bank
										Transfer or PayPal. <Link to="/earnings-disclaimer">Earnings Disclaimer</Link>
									</p>
								</article>
								<div className="background" />
							</div>
						</section>


						<section className="brands">
							<h2 className="brands-title">Some of the brands that <em>we work with</em></h2>
							<BrandsSlider hideTitle={true} />

							<button className="lander-button1"
									onClick={() => {this.showModal("signup", "cta5new");}}>
								<p>JOIN now for FREE!</p>
								<FontAwesomeIcon className="chevron" icon={faChevronRight} />
							</button>
						</section>

						<footer>
							<div className="footer-layout">
								<img
									src="https://cashback-public.s3.eu-west-2.amazonaws.com/homepage/cashback-white.svg"
									alt="Cashback"
									loading="lazy"
									width="200"
									height="40"
									className="logo" />
								<ul>
									<li>
										<a href="/signup"
										   accessKey="6"
										   onClick={(event) => {
											   event.preventDefault();
											   this.showModal("signup", "cta6new");
										   }}
										>Sign Up</a>
									</li>
									<li>
										<a href="/login"
										   accessKey="7"
										   onClick={(event) => {
											   event.preventDefault();
											   this.showModal("login");
										   }}>Login</a>
									</li>
									<li><HelpdeskLoader /></li>
									<li><Link to="/privacy-notice" accessKey="9">Privacy Notice</Link></li>
									<li><Link to="/terms-and-conditions" accessKey="b">Terms & Conditions</Link></li>
									<li><Link to="/affiliates" accessKey="c">Affiliates</Link></li>
									<li><Link to="/earnings-disclaimer" accessKey="d">Earnings Disclaimer</Link></li>
								</ul>
								<button className="top-button" onClick={() => { window.scrollTo(0, 0); }}>
									<img
										src="https://cashback-public.s3.eu-west-2.amazonaws.com/homepage/totop-white.svg"
										alt="Scroll to the top"
										width="40"
										height="37"
										loading="lazy"
									/>
								</button>
							</div>
						</footer>
					</div>
				</main>
			</>
		);
	}
}

Lander.propTypes = {
	mainTitleJsx: PropTypes.element,
	mainSubTitleJsx: PropTypes.element,
	hidePreview: PropTypes.bool,
	referredByUserUuid: PropTypes.string,
	partnerBrand: PropTypes.string
};

export default withRouter(Lander);