import React from "react";
import handleSubmitFunc from "./ApiCalls/handleSubmitFunc";
import ApiFunc from "./ApiCalls/ApiFunc";
import Form from "./Form";
import { lookupTypes, replaceVariables } from "../utilities/TextLookups";
import ErrorCard from "./ErrorCard";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Redirect } from "react-router";
import { withAlert } from "react-alert";
import { sendResetPasswordEmail } from "./ApiCalls/sendResetPasswordEmail";
import Loader from "./Loader";
import { local, session } from "../utilities/storage";
import * as Sentry from "@sentry/react";

class LoginForm extends React.Component {
	_isMounted = false;
	checkInterval = null;

	constructor (props) {
		super(props);

		this.state = {
			"requirements": {},
			"user": {},
			"redirect": null,
			"loading": false,
			"formReady": false,
		};

		this.handleForgotPasswordClick = this.handleForgotPasswordClick.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleForgotPasswordClick () {
		const { user } = this.state;
		if (!user?.email) {
			this.props.alert.error("Please enter your email address first");
			return;
		}

		sendResetPasswordEmail(user.email, this.props.alert);
	}

	handleChange (name, value) {
		this.setState(prevState => ({
			user: {
				...prevState.user,
				[name]: value
			}
		}));
	}

	handleSubmit (event) {
		event.preventDefault();
		let stateUserFields = this.state.user;
		this.setState({ loading: true });

		handleSubmitFunc(stateUserFields, "user", "POST",
			"login-by-email", true, null,
			data => {
				this.setState({ loading: false });

				if (data.token) {
					local.setItem("user_token", data.token.access_token);
					local.setItem("user_uuid", data.user.uuid);
					local.setItem("user_email", data.user.email);
					local.setItem("agreement_decision", data.user.usage.contact ? "yes" : "no");

					if (data.user?.data?.fb_conversion) {
						local.setItem("fb_conversion", true);
					}

					this.props.alert.success("Successful log in", {
						timeout: 1500,
						onClose: () => {
							if (this.props.redirectTo.startsWith("http")) {
								window.location.replace(replaceVariables(this.props.redirectTo));
								return;
							}
							this.setState({ redirect: <Redirect to={this.props.redirectTo} /> });

							if (this.props.onLogin) {
								this.props.onLogin();
							}
						}
					});
				}
			}, error => {
				this.setState({ loading: false });
				const data = this.state.user;
				let userPassword = data.password;

				if (data.password) {
					data.password = "****";
				} else {
					data.password = "[missing]";
				}
				Sentry.captureMessage("Login error: " + error.toString() + " - " + JSON.stringify(data));
				this.props.alert.error(error.toString());

				data.password = userPassword;
			});
	}

	componentDidMount () {
		this._isMounted = true;

		ApiFunc(`user/login-by-email/post-requirements`)
			.then(response => response.json())
			.then(data => {
				if (this._isMounted) {
					this.setState({ requirements: data.requirements });
				}
			})
			.catch(error => this.setState({
				isLoading: false,
				message: "Something bad happened: " + error
			}));

		this.checkStorageItems();
	}

	componentWillUnmount () {
		this._isMounted = false;
		if (this.checkInterval) {
			clearInterval(this.checkInterval);
		}
	}

	checkStorageItems = () => {
		this.checkInterval = setInterval(() => {
			const ip = session.getItem("ip");
			const trackUuid = local.getItem("track_uuid");
			if (ip && trackUuid) {
				if (this._isMounted) {
					this.setState({ formReady: true });
					clearInterval(this.checkInterval);
				}
			}
		}, 250); // Check every 250 milliseconds
	};

	render () {
		let { requirements, user, message, redirect, loading, formReady } = this.state;
		let toDisplay;

		if (requirements) {
			if (requirements.track_uuid) {
				requirements.track_uuid.type = "hidden";
				user.track_uuid = local.getItem("track_uuid");
				user.ip = session.getItem("ip");
			}

			if (requirements.ip) {
				requirements.ip.type = "hidden";
			}

			if (requirements.email) {
				requirements.email.icon = <FontAwesomeIcon icon={faEnvelope} className="input-icon" />;
				requirements.email.autoComplete = "email";
				requirements.email.placeholder = "Email/Username";
			}

			if (requirements.password) {
				requirements.password.icon = <FontAwesomeIcon icon={faLock} className="input-icon" />;
				requirements.password.autoComplete = "current-password";
				requirements.password.placeholder = "Password";
			}

			if (requirements.ip) {
				requirements.ip.type = "hidden";
			}

			if (requirements.track_uuid) {
				requirements.track_uuid.type = "hidden";
			}

			toDisplay = (
				<>
					<Form
						handleSubmit={this.handleSubmit}
						onFieldChange={this.handleChange}
						formType={lookupTypes.USER}
						requirements={requirements}
						fieldValues={user}
						hideLabels={true}
						hideRequiredLabels={true}
						submitText="Log in"
						submitting={!formReady || loading}
					/>
					<button className="login-forgot_password" onClick={this.handleForgotPasswordClick}>
						Reset your password
					</button>
					<Loader visible={!formReady || loading} />
				</>
			);
		} else {
			toDisplay = <ErrorCard message={message} />;
		}

		return redirect === null ? toDisplay : redirect;
	}
}

export default withAlert()(LoginForm);