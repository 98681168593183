import { allowedIps, blockedIsps } from "../../utilities/tracking";
import { retryPromiseWithDelay } from "../../utilities/promise";

export function checkGeo () {
	return retryPromiseWithDelay(() => checkGeoFn(), 1000, 2);
}

async function checkGeoFn () {

	// ByPass Geo check if url contains bpg=3OibkBCwAx for facebook connect verification
	const urlParams = new URLSearchParams(window.location.search);
	if (urlParams.get("bpg") === "3OibkBCwAx") {
		return true;
	}


	let response = await fetch(`https://ships.stechga.co.uk`);
	let data = await response.json();

	if (allowedIps.includes(data?.ip)) {
		return true;
	}

	const allowedCountries = ["GB", "UK"];
	const countryCode = data?.country_code;

	if (!allowedCountries.includes(countryCode)) {
		return false;
	}

	let isp = data?.isp_name ?? "";
	isp = isp.toLowerCase();

	return !blockedIsps.includes(isp);
}