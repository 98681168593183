import React, { Component } from "react";
import PropTypes from "prop-types";

class QuestionTransactional extends Component {
	render () {
		return (
			<>
				<p className="survey-question">{"Q" + (this.props.questionNumber || 4) +
					" of " + (this.props.totalQuestions || 4) + ": You have selected no to email marketing, would you" +
					" like us to email you when your tasks track or become payable?"}</p>

				<label htmlFor="box1">
					<input type="radio"
						   name="cbuk_survey_transactional"
						   value="yes"
						   id="box1"
						   onClick={this.props.onClick}
					/>
					Yes
				</label>

				<label htmlFor="box2">
					<input type="radio"
						   name="cbuk_survey_transactional"
						   value="no"
						   id="box2"
						   onClick={this.props.onClick}
					/>
					No
				</label>
			</>
		);
	}
}

QuestionTransactional.propTypes = {
	questionNumber: PropTypes.number,
	totalQuestions: PropTypes.number,
	onClick: PropTypes.func.isRequired
};

export default QuestionTransactional;