import React from "react";
import { Link } from "react-router-dom";

export default function CreateLink(props) {

    let {text, linkTo, id = null, param = '', classes = ''} = props;

    if (id === 'create') {
        classes += " button is-align-self-flex-end is-primary";
    }
    if (id === 'button') {
        classes += " button is-link";
    }
    if (id === 'goBack') {
        classes += " button is-align-self-flex-start is-outlined";
    }

    return (
        <Link to={ linkTo + param } id={id} className={classes}>
            {text}
        </Link>
    );
}