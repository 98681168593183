import React, { Component } from "react";
import PropTypes from "prop-types";

class QuestionGender extends Component {
	render () {
		return (
			<>
				<p className='survey-question'>{"Q" + (this.props.questionNumber || 2) +
					" of " + (this.props.totalQuestions || 3) + ": Which gender do you identify as?"}</p>

				<label htmlFor="gender1">
					<input type="radio"
						   name="cbuk_survey_user_gender"
						   value="Female"
						   id="gender1"
						   onClick={this.props.onClick}
					/>
					Female
				</label>

				<label htmlFor="gender2">
					<input type="radio"
						   name="cbuk_survey_user_gender"
						   value="Male"
						   id="gender2"
						   onClick={this.props.onClick}
					/>
					Male
				</label>

				<label htmlFor="gender3">
					<input type="radio"
						   name="cbuk_survey_user_gender"
						   value="Gender Variant"
						   id="gender3"
						   onClick={this.props.onClick}
					/>
					Gender Variant
				</label>

				<label htmlFor="gender4">
					<input type="radio"
						   name="cbuk_survey_user_gender"
						   value="Prefer Not To Answer"
						   id="gender4"
						   onClick={this.props.onClick}
					/>
					Prefer Not To Answer
				</label>
			</>
		);
	}
}

QuestionGender.propTypes = {
	questionNumber: PropTypes.number,
	totalQuestions: PropTypes.number,
	onClick: PropTypes.func.isRequired
};

export default QuestionGender;