import React, { Component } from "react";
import PropTypes from "prop-types";
import ApiFunc from "./ApiCalls/ApiFunc";
import Content from "./Content";
import { getUserUuid } from "../utilities/tracking";
import * as Sentry from "@sentry/react";
import { local } from "../utilities/storage";

class Agreement extends Component {
	_isMounted = false;

	constructor (props) {
		super(props);

		this.state = {
			currentAgreement: {
				agreement: ""
			}
		};
	}

	componentDidMount () {
		this._isMounted = true;
		this.fetchAgreements();
	}

	componentWillUnmount () {
		this._isMounted = false;
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		const { currentAgreement } = this.state;

		if (this.show() && currentAgreement.agreement === "") {
			// no agreement published yet to show
			this.props.onComplete();
		}
	}

	fetchAgreements () {
		ApiFunc(`published-agreements/public`)
			.then(response => response.json())
			.then(data => {
				if (data.published_agreements?.results && data.published_agreements.results[0]) {
					// assume the first returned agreement is the one to use for now
					if (this._isMounted) {
						this.setState({ currentAgreement: data.published_agreements.results[0] });
					}
				}
			})
			.catch(error => {
				// alert("Error when fetching agreements: ", error);
				console.error(error);
			});
	}

	logDecision (answer, onSuccess = () => {}, onFailure = () => {}) {
		const params = {
			user_uuid: getUserUuid(),
			agreement_uuid: this.state.currentAgreement.agreement_uuid,
			answer: answer
		};

		ApiFunc("agreement/create-log", "POST", params)
			.then(response => response.json())
			.then(data => {
				if (data.agreement_log) {
					onSuccess(data);
					return;
				}
				onFailure(data);
			})
			.catch(error => {
				alert("Error when logging agreement answer. ", error);
				console.error(error);
			});
	}

	decide (action) {
		this.logDecision(action,
			data => {
				local.setItem("agreement_decision", action);
				this.props.onComplete();
			},
			data => {
				Sentry.captureException(new Error("Agreement decision error: " + JSON.stringify(data)));
			});
	}

	show () {
		const { currentAgreement } = this.state;

		if (!getUserUuid()) {
			return false;
		}

		if (!currentAgreement || currentAgreement.agreement === "") {
			return false;
		}

		if (this.props.userAgreements === null) {
			return true;
		}

		if (this.props.userAgreements === undefined) {
			return false;
		}

		if (this.props.userAgreements) {
			const matchingAgreement = this.props.userAgreements.find(a => a.agreement_uuid === currentAgreement.agreement_uuid);

			return !matchingAgreement;
		}

		return true;
	}

	render () {
		const { currentAgreement } = this.state;

		let content = <p>{currentAgreement.agreement}</p>;
		if (currentAgreement?.data && currentAgreement.data?.html_content !== null) {
			content = <Content html={currentAgreement.data.html_content} />;
		}

		return (
			<section
				className={"agreement-layout overlay " + (this.show() ? "show" : "")}>
				<div className="agreement modal-small">
					<h2>{currentAgreement?.data && currentAgreement.data?.question !== null ?
						currentAgreement.data.question : currentAgreement.name}</h2>
					{content}
					<div className="buttons-layout">
						<button className="btn-no" onClick={() => { this.decide("no"); }}>No</button>
						<button className="btn-yes" onClick={() => { this.decide("yes"); }}>Yes</button>
					</div>
				</div>
			</section>
		);
	}
}

Agreement.propTypes = {
	userAgreements: PropTypes.array,
	onComplete: PropTypes.func.isRequired
};

export default Agreement;