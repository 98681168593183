import React, { Component } from "react";

const TrustSlider = ({ trustSliderRef }) => (
	<div ref={trustSliderRef}
		 className="trustpilot-widget trustpilot-slider"
		 data-locale="en-GB"
		 data-template-id="54ad5defc6454f065c28af8b"
		 data-businessunit-id="573ed1070000ff00058d1d66"
		 data-style-height="240px"
		 data-style-width="100%"
		 data-theme="light"
		 data-stars="4,5"
		 data-tags="Homepage"
		 data-review-languages="en">
		<a href="https://uk.trustpilot.com/review/www.cashback.co.uk" target="_blank"
		   rel="noopener">Trustpilot</a>
	</div>
);

class TrustPilotSlider extends Component {
	constructor (props) {
		super(props);
		this.trustSliderRef = React.createRef();
	}

	componentDidMount () {
		if (window.Trustpilot) {
			window.Trustpilot.loadFromElement(this.trustSliderRef.current, true);
		}
	}

	componentDidUpdate () {
		if (window.Trustpilot) {
			window.Trustpilot.loadFromElement(this.trustSliderRef.current, true);
		}
	}

	render () {
		return <TrustSlider trustSliderRef={this.trustSliderRef} />;
	}
}

export default TrustPilotSlider;