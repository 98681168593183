import { format, parseISO } from "date-fns";
import { enGB } from "date-fns/esm/locale";
import { decode } from "html-entities";

export function stripSpaces (input) {
	return input.replace(/\s/g, "");
}

export function stripHtml (input) {
	return decode(input.replace(/(<([^>]+)>)/ig, ""));
}

export function zeroPad (num, places) {
	if (num === "") return "";
	if (typeof String(num).padStart !== "function") return String(num);

	return String(num).padStart(places, "0");
}

export function formatMoney (pence, currency = "GBP", poundsOnly = false, showZero = true) {
	const precision = poundsOnly ? {
		minimumFractionDigits: 0,
		maximumFractionDigits: 0
	} : {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	};

	if(pence === null || pence === undefined){
		return "Loading";
	}

	if(pence === 0 && showZero === false){
		return "TBC";
	}

	return new Intl.NumberFormat("en-GB", { style: "currency", currency, ...precision }).format(pence / 100);
}

export function camelCase (input) {
	return input.toLowerCase().replace(/-(.)/g, function(match, group1) {
		return group1.toUpperCase();
	});
}

export function formatDate (isoDate) {
	let date = parseISO(isoDate);
	return format(date, "dd/MM/Y", { locale: enGB });
}