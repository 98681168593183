import React, { Component } from "react";
import { addCanonical } from "../../utilities/addTagsToHead";
import { setCookie } from "../../utilities/cookies";
import Lander202304 from "./Lander202304";

class BonusLander extends Component {
	componentDidMount () {
		addCanonical();
		const welcomeBonusUuid = process.env.NODE_ENV === "development" ?
			"3212064b-1ed2eb39-99ba-605e-840e-444230303035" : "3212064b-1ed2eb76-b6a7-62ce-915e-444230303035";
		setCookie("welcome_bonus_uuid", welcomeBonusUuid, { "max-age": 1200 });
	}

	render () {
		return this.props.lander || <Lander202304 {...this.props} />
	}
}

export default BonusLander;