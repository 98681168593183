import React, { Component } from "react";

class ComingSoon extends Component {
	componentDidMount () {
		document.title = 'Coming soon... Cashback!';
	}

	render () {
		return (
			<div className="unknown-page">
				<img src='/images/favicon-228.png' alt='Cashback' />
				<h1>Coming soon... Cashback!</h1>
			</div>
		);
	}
}

export default ComingSoon;