import React, { useRef } from "react";
import Glide from "react-glidejs";

import "react-glidejs/dist/index.css";

export default props => {
	const gliderRef = useRef(null);
	const title = props.hideTitle ? <></> : <p>Brands we work with...</p>;

	return (
		<div className="brands">
			<Glide
				ref={gliderRef}
				className="slider"
				type="carousel"
				autoplay={props.autoplay || '2000'}
				animationDuration={props.animationDuration || '400'}
				animationTimingFunc={props.animationTimingFunc || 'cubic-bezier(0.165, 0.840, 0.440, 1.000)'}
				hideArrows={true}
				hoverPause={false}
				focusAt={props.focusAt || "center"}
				perView={props.perView || 1}
			>
				<div className="center">
					<img src="https://cashback-public.s3.eu-west-2.amazonaws.com/homepage/Experian500x140.png"
						 alt="Experian"
						 width="350"
						 height="98" />
				</div>
				<div className="center">
					<img src="https://cashback-public.s3.eu-west-2.amazonaws.com/homepage/Lifepoints500x140.png"
						 alt="Lifepoints"
						 width="350"
						 height="98" />
				</div>
				<div className="center">
					<img src="https://cashback-public.s3.eu-west-2.amazonaws.com/homepage/Nielsen500x140.png"
						 alt="Nielsen"
						 width="350"
						 height="98" />
				</div>
				<div className="center">
					<img src="https://cashback-public.s3.eu-west-2.amazonaws.com/homepage/MoneySuperMarket.png"
						 alt="MoneySuperMarket"
						 width="350"
						 height="98" />
				</div>
				<div className="center">
					<img src="https://cashback-public.s3.eu-west-2.amazonaws.com/homepage/Readly-grey-500x140.png"
						 alt="Readly"
						 width="350"
						 height="98" />
				</div>
				<div className="center">
					<img src="https://cashback-public.s3.eu-west-2.amazonaws.com/homepage/SimplyCook500x140.png"
						 alt="SimplyCook"
						 width="350"
						 height="98" />
				</div>
				<div className="center">
					<img src="https://cashback-public.s3.eu-west-2.amazonaws.com/homepage/Smol500x140.png"
						 alt="Smol"
						 width="350"
						 height="98" />
				</div>
			</Glide>
			{title}
		</div>
	);
};