import React, { Component } from "react";
import { Link } from "react-router-dom";
import { addCanonical } from "../../utilities/addTagsToHead";

class Subscribed extends Component {
	componentDidMount () {
		addCanonical();
		setTimeout(() => {
			window.location.href = "/";
		}, 5000);
	}

	render () {
		return (
			<div className="subscribed-page">
				<img src="https://cashback-public.s3.eu-west-2.amazonaws.com/logo/favicon-228.png" alt="Cashback" />
				<p>Email confirmed successfully.</p>
				<p>Thanks for subscribing.  Now, lets do some tasks!</p>
				<Link to="/" className="btn btn-type3">Get started</Link>
			</div>
		);
	}
}

export default Subscribed;