import React, { Component } from "react";
import PropTypes from "prop-types";

class Loader extends Component {
	constructor (props) {
		super(props);

		this.state = {
			show: false
		};
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (this.props.visible === true && prevProps.visible === false) {
			this.timerId = setTimeout(() => {
				this.setState({ show: true });
			}, 1000);
		}

		if (this.props.visible === false && prevProps.visible === true) {
			// cancel timer
			clearTimeout(this.timerId);
			this.setState({ show: false });
		}
	}

	render () {
		const { show } = this.state;

		return (
			<div className={show ? "loader" : "loader hidden"} />
		);
	}
}

Loader.propTypes = {
	visible: PropTypes.bool
};

export default Loader;