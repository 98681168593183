import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

class RungsHeader extends Component {
	constructor (props) {
		super(props);
		this.state = {
			keyExpanded: true
		};
	}

	componentDidUpdate () {
		const activeRung = document.querySelector(".rung.active");

		if(activeRung){
			activeRung.scrollIntoView({ behavior: "smooth", block: "end", inline: "center" });
		}
	}

	render () {
		let rungs = <></>;

		if (this.props.rungs && Object.keys(this.props.rungs).length > 0) {
			rungs = this.props.rungs.map((rung, i) => {
				const classes = "rung " +
					(this.props.activeRungNumber > rung.ladder_position ? "next " : "") +
					(this.props.activeRungNumber === rung.ladder_position ? "active " : "") +
					rung.status;

				let Element = () => (
					<Link to={"/tasks/" + rung.url_slug}>
						<div className={classes}>
							{rung.ladder_position}
						</div>
					</Link>
				);

				if (this.props.activeRungNumber === rung.ladder_position || rung.status === "locked") {
					Element = () => (
						<div className={classes}>
							{rung.ladder_position}
						</div>
					);
				}

				return (
					<li key={i}>
						<Element />
					</li>
				);
			});
		}

		return (
			<header className={"rungs-header " + (this.props.className ?? '')}>
				<aside className="legend">
					<ul className={"key " + (this.state.keyExpanded ? "expanded" : "")}>
						<li>
							<div className="key-icon locked" />
							Locked
						</li>
						<li>
							<div className="key-circle pending" />
							Pending
						</li>
						<li>
							<div className="key-circle completed" />
							Confirmed
						</li>
						<li>
							<div className="key-circle rejected" />
							Rejected
						</li>
					</ul>
					{/*<div className={"opener " + (this.state.keyExpanded ? "expanded" : "")} onClick={() => {*/}
					{/*	this.setState(prevState => (*/}
					{/*		{*/}
					{/*			keyExpanded: !prevState.keyExpanded*/}
					{/*		}*/}
					{/*	));*/}
					{/*}}>Key*/}
					{/*</div>*/}
				</aside>

				<ul className="rung-list">
					{rungs}
				</ul>
			</header>
		);
	}

}

export default RungsHeader;

RungsHeader.propTypes = {
	rungs: PropTypes.array.isRequired,
	activeRungNumber: PropTypes.number,
	className: PropTypes.string
};