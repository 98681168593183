import React from "react";
import { Redirect } from "react-router";
import { getToken } from "../../utilities/tracking";
import { withAlert } from "react-alert";
import Lander202304 from "./Lander202304";

class Home extends React.Component {
	constructor (props) {
		super(props);
	}

	componentDidMount () {
		this.showHelpdeskError();
	}

	showHelpdeskError () {
		const u = new URL(location.href);
		if (u.searchParams.has("show-helpdesk-error")) {
			this.props.alert.error(
				<p>There was a problem with the helpdesk. <br/>
					Please try again later or email: <a href='mailto:members@cashback.co.uk'>members@cashback.co.uk</a>
				</p>, {
				timeout: 0
			});
			window.history.pushState({}, document.title, window.location.pathname);
		}
	}

	render () {
		const token = getToken();
		const loggedIn = (token !== null);
		const params = window.location.search;
		const isError = params.includes('show-helpdesk-error');

		return (
			<>
				{loggedIn && !isError ? <Redirect to={{
					pathname: "/tasks" + params,
					state: { redirected: true }
				}} /> : <Lander202304 />}
			</>
		);
	}
}

export default withAlert()(Home);