import React, { Component } from "react";

const TrustBox = ({ trustBoxRef, theme }) => (
	<>
		<div id={theme === "light" ? "trustpilotHolderLight" : "trustpilotHolderDark"}>
			<div ref={trustBoxRef}
				 className="trustpilot-widget"
				 data-locale="en-GB"
				 data-template-id="53aa8807dec7e10d38f59f32"
				 data-businessunit-id="573ed1070000ff00058d1d66"
				 data-style-height="120px"
				 data-style-width="100%"
				 data-theme={theme}>
				<a href="https://uk.trustpilot.com/review/www.cashback.co.uk"
				   target="_blank"
				   rel="noopener noreferrer">Trustpilot
				</a>
			</div>
		</div>
	</>
);

class TrustPilot extends Component {
	constructor (props) {
		super(props);
		this.trustBoxRef = React.createRef();
	}

	componentDidMount () {
		if (window.Trustpilot) {
			window.Trustpilot.loadFromElement(this.trustBoxRef.current, true);
		}
	}

	render () {
		return <TrustBox trustBoxRef={this.trustBoxRef}
						 theme={this.props.theme} />;
	}
}

export default TrustPilot;