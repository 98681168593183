import OneSignal from "react-onesignal";
import { getUserUuid } from "./tracking";
import { session } from "./storage";
import ApiFunc from "../Components/ApiCalls/ApiFunc";
import { getCookie } from "./cookies";
import { local } from "./storage";


export function startOneSignal () {
	if (window.OneSignal) {
		return;
	}

	const bellBackgroundColor = "rgb(230,0,126)";
	const bellForegroundColor = "white";
	const badgeBackgroundColor = "rgb(230,0,126)";
	const badgeForegroundColor = "white";
	const badgeBorderColor = "white";
	const pulseColor = "white";
	const buttonHoverBackgroundColor = "rgb(215,0,118)";
	const buttonActiveBackgroundColor = "rgb(217,0,119)";
	const buttonBackgroundColor = "rgb(230,0,126)";
	const buttonForegroundColor = "white";

	const devAppId = "608e2672-9c9f-4b32-bedd-0777e7021ac4";
	const prodAppId = "49611fba-5144-4308-a3cc-29da5aa7cd97";

	const appId = process.env.NODE_ENV === "development" ? devAppId : prodAppId;

	OneSignal.init({
		appId: appId,
		safari_web_id: "web.onesignal.auto.5d035d80-811e-4f05-a17d-f7e13950e2b6",
		allowLocalhostAsSecureOrigin: true,
		persistNotification: true,
		welcomeNotification: {
			disable: true
		},
		promptOptions: {
			siteName: "Cashback",
			actionMessage: "Keep Tabs On Your Progress And Next Tasks With Notifications...",
			exampleNotificationTitle: "Example notification",
			exampleNotificationMessage: "This is an example notification",
			exampleNotificationCaption: "You can unsubscribe anytime",
			acceptButtonText: "ALLOW",
			cancelButtonText: "NO THANKS",
			slidedown: {
				enabled: true,
				autoPrompt: false
			}
		},
		notifyButton: {
			enable: true,
			size: "medium", /* One of 'small', 'medium', or 'large' */
			theme: "default", /* One of 'default' (red-white) or 'inverse" (white-red) */
			position: "bottom-left", /* Either 'bottom-left' or 'bottom-right' */
			offset: {
				bottom: "4em",
				left: "calc(1vw + 0.05rem)" /* Only applied if bottom-left */
			},
			prenotify: true, /* Show an icon with 1 unread message for first-time site visitors */
			showCredit: false, /* Hide the OneSignal logo */
			text: {
				"tip.state.unsubscribed": "Subscribe to notifications",
				"tip.state.subscribed": "You're subscribed to notifications",
				"tip.state.blocked": "You've blocked notifications",
				"message.prenotify": "Click to subscribe to notifications",
				"message.action.subscribed": "Thanks for subscribing!",
				"message.action.resubscribed": "You're subscribed to notifications",
				"message.action.unsubscribed": "You won't receive notifications again",
				"dialog.main.title": "Manage Notifications",
				"dialog.main.button.subscribe": "SUBSCRIBE",
				"dialog.main.button.unsubscribe": "UNSUBSCRIBE",
				"dialog.blocked.title": "Unblock Notifications",
				"dialog.blocked.message": "Follow these instructions to allow notifications:"
			},
			colors: { // Customize the colors of the main button and dialog popup button
				"circle.background": bellBackgroundColor,
				"circle.foreground": bellForegroundColor,
				"badge.background": badgeBackgroundColor,
				"badge.foreground": badgeForegroundColor,
				"badge.bordercolor": badgeBorderColor,
				"pulse.color": pulseColor,
				"dialog.button.background.hovering": buttonHoverBackgroundColor,
				"dialog.button.background.active": buttonActiveBackgroundColor,
				"dialog.button.background": buttonBackgroundColor,
				"dialog.button.foreground": buttonForegroundColor
			}
		}
	});
}

export function trackOneSignalPrompt () {
	let osp = session.getItem("oneSignalPrompt");
	if (osp > 1) {
		osp--;
		session.setItem("oneSignalPrompt", osp);
		return;
	}

	if (osp === null) {
		const u = new URL(location.href);
		osp = u.searchParams.get("osp");
		if (osp !== null) {
			session.setItem("oneSignalPrompt", parseInt(osp, 10));
		}
	}

	if (osp === "1") {
		showOneSignalPrompt(5000, true);
		session.removeItem("oneSignalPrompt");
		return;
	}

	if (osp === "0") {
		session.removeItem("oneSignalPrompt");
	}
}

export function showOneSignalPrompt(delay = 5000, force = false, native = false) {
	const checkInterval = setInterval(() => {
		if (!!OneSignal && !!getUserUuid() && !!local.getItem('agreement_decision')) {
			clearInterval(checkInterval); // Stop the interval check

			let options = null;
			if (force) {
				options = {force: true};
			}

			setTimeout(() => {
				if (native) {
					OneSignal.showNativePrompt();
				} else {
					OneSignal.showSlidedownPrompt(options);
				}
			}, delay);

			OneSignal.on("subscriptionChange", oneSignalSubscriptionChange);
		}
	}, 500);
}

export function oneSignalSubscriptionChange (isSubscribed) {
	if (isSubscribed) {
		updateOneSignalPlayerId();
	} else {
		const userUuid = getUserUuid();
		ApiFunc(`user/data/${userUuid}`, "PATCH", { data: { one_signal_player_id: null } });
	}
}

export function updateOneSignalPlayerId () {
	const userUuid = getUserUuid();

	if (!userUuid) {
		return;
	}

	OneSignal.setExternalUserId(userUuid);

	OneSignal.getUserId()
		.then(playerId => {
			if (typeof playerId === "string") {
				const data = {
					data: {
						one_signal_player_id: playerId
					}
				};

				if (getCookie("held_admin") !== undefined) {
					ApiFunc(`user/data/${userUuid}`, "PATCH", data);
				}
			}
		});
}