import React, { useEffect, Suspense } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { Routes } from "../Routes";
import { getMyIP, updateTracking } from "./tracking";

const UnknownPage = React.lazy(() => import("../Components/Pages/UnknownPage"));

let routeFound = false;
let flattenedRoutes = [];

function setParent (route) {
	if (route.children !== undefined) {
		route.children.forEach(item => {
			const { children, ...otherProperties } = route;
			item.parent = otherProperties;
			setParent(item);
		});
	}
}

function AppRouting (props) {
	const routes = flattenedRoutes.map((route, index) => {
		const { component, ...routeParams } = route;
		return (
			<Route key={index}
				   {...routeParams}
				   {...props}
				   render={(props2) => React.cloneElement(component, { ...props, ...props2 })}>
			</Route>
		);
	});

	const location = useLocation();

	useEffect(() => {
		const title = getPageTitle(location.pathname);
		if (title) {
			document.title = title;
		}
		getMyIP();
		if(location?.state?.redirected !== true){
			updateTracking();
		}
	});

	return (
		<Switch>
			{routes}
			<Route><Suspense fallback={<></>}><UnknownPage /></Suspense></Route>
		</Switch>
	);
}

function getPageTitle (searchPath) {
	searchPath = replaceUuid(searchPath);
	const route = flattenedRoutes.find(route => route.path === searchPath);

	if (route?.title) {
		return route.title + " | Cashback";
	}
}

function InitRouting () {
	routeFound = false;
	flattenedRoutes = [];
	setParent(Routes);
	generateFlattenedRoutes(Routes);
}

function CurrentRoute (searchPath) {
	searchPath = replaceUuid(searchPath);
	routeFound = false;
	return findDeep(Routes.children, searchPath);
}

function findDeep (data, searchPath) {
	if (routeFound) {
		return routeFound;
	}
	data.find(function(route) {
		if (route.path === searchPath) {
			routeFound = route;
			return true;
		} else if (route.children) {
			findDeep(route.children, searchPath);
			return false;
		}
		return false;
	});
	if (routeFound) {
		const { children, ...rest } = routeFound;
		return getParentsArray(rest);
	}

	return false;
}

function replaceUuid (urlslug) {
	return urlslug.replace(/\b[0-9a-f]{8}-[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\b/, ":uuid");
}

function getParentsArray (route) {
	let breadcrumbs = [];

	do {
		let { parent, ...r } = route;
		breadcrumbs.push(r);
	} while (route = getParent(route));

	return breadcrumbs.reverse();
}

function getParent (route) {
	if (route.parent) {
		return route.parent;
	}

	return false;
}

function generateFlattenedRoutes (routes) {
	const { children, ...route } = routes;

	flattenedRoutes.push(route);

	if (children) {
		children.forEach(child => {
			generateFlattenedRoutes(child);
		});
	}
}

export { InitRouting, AppRouting };