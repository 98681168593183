import React, { Component } from "react";

class VideoBox extends Component {
	constructor (props) {
		super(props);
	}

	render () {
		const video = <iframe src={"https://www.youtube.com/embed/" + this.props.videoId + "?&autoplay=1"}
							  title="How Cashback Works"
							  className="video-frame"
							  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							  allowFullScreen={true} />;

		return (
			<div className={this.props.show ? "overlay" : "overlay hide"}
				 onClick={this.props.handleClose}>
				<span className="video-close" onClick={this.props.handleClose}>×</span>
				<div className="videoContainer">
					{this.props.show ? video : <></>}
				</div>
			</div>
		);
	}
}

export default VideoBox;