import React from "react";
import { parseISO, format, parseJSON } from "date-fns";
import CreateLink from "../Components/CreateLink";

export default function convertDataFunc(key, keywords = false) {

	let dataArr = [];
	let formName = key[0];
	let formNameVal = key[1];

	const dateFieldNames = ['modified','created','expire','start'];
	const dateStringFieldNames = ['last_login','status_datetime'];

	if(formName === 'system-datetime'){
		let date = formNameVal;

		if(typeof formNameVal === 'object'){
			date = formNameVal.date;
		}

		if(!date) {
			return '';
		}

		const parsedDate = parseISO(date)
		return format(parsedDate, "y-MM-dd'T'HH:mm:ss") ?? '';
	}

	if(formName === 'system-date'){
		let date = formNameVal;

		if(typeof formNameVal === 'object'){
			date = formNameVal.date;
		}

		if(!date) {
			return '';
		}

		const parsedDate = parseISO(date)
		return format(parsedDate, "y-MM-dd") ?? '';
	}

	if(formName === 'date-parts'){
		let date = {
			day: '',
			month: '',
			year: ''
		};

		if (formNameVal){
			let parts = formNameVal?.split('-');
			date.year = parts[0] ?? '';
			date.month = parts[1] ?? '';
			date.day = parts[2] ?? '';
		}

		return date;
	}

	if (typeof formNameVal != "string" && formNameVal !== null) {
		if (typeof (formNameVal) === Array) {
			formNameVal = formNameVal.join();
		}

		if ( dateFieldNames.includes(formName) ) {
			const parsedDate = parseJSON(formNameVal.date)
			formNameVal = format(parsedDate, "d MMMM y HH:mm:ss");
		}
		if (formName === 'categories_data') {
			formNameVal = formNameVal.map(category => category.name).join(', ');
		}
		if (keywords) {
			if (formName === 'keywords') {
				formNameVal = formNameVal.join();
			}
		}
		// TODO: Investigate if the below is required
		if (formName === 'data') {
			Object.entries(formNameVal).map(key => {
				dataArr[key[0]] = key[0] + ": "+  key[1];
			});
			formNameVal = JSON.stringify(dataArr);
		}
	}

	if ( dateStringFieldNames.includes(formName) ) {
		const parsedDate = parseJSON(formNameVal);
		formNameVal = format(parsedDate, "d MMMM y HH:mm:ss");
	}

	if (formName === 'linked_ladder_offer_uuid' && formNameVal !== null) {
		formNameVal = <CreateLink key={formNameVal} text='Ladder offer' linkTo={'/ladderOfferDetails/' + formNameVal} />;
	}

	return formNameVal;

}