import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import Menu from "./Menu";
import { Link } from "react-router-dom";
import { getToken, trackContent } from "../utilities/tracking";
import PropTypes from "prop-types";
import { session } from "../utilities/storage";
import HelpdeskLoader from "./HelpdeskLoader";

class Header extends Component {
	constructor (props) {
		super(props);
		this.state = {
			menuExpanded: false,
			dropdownOpen: false
		};
		this.handleToggleMenu = this.handleToggleMenu.bind(this);
		this.handleShowDropdown = this.handleShowDropdown.bind(this);
	}

	handleShowDropdown () {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	}

	handleToggleMenu () {
		this.setState(prevState => ({
			menuExpanded: !prevState.menuExpanded
		}));
	}

	render () {
		const loggedIn = (getToken() !== null);
		const hideBonusOffersSources = ["tiktok"];
		let hideBonusOffers = "";
		if (hideBonusOffersSources.includes(session.getItem("source"))) {
			hideBonusOffers = " hide";
		}

		const loggedOutLinks = (
			<ul className="header-entry">
				<li className="link top-signup">
					<a href="/signup"
					   className="signup-pagelink"
					   onClick={(event) => {
						   event.preventDefault();
						   this.props.showModal("signup", this.props.forLander ? "cta1new" : "cta1");
					   }}
					>Sign Up</a>
				</li>
				<li className="divider" />
				<li className="link top-login">
					<a href="/login"
					   accessKey="z"
					   className="open-login-box"
					   onClick={(event) => {
						   event.preventDefault();
						   this.props.showModal("login");
					   }}
					>Log in</a>
				</li>
			</ul>
		);

		let surveyLink;

		if (SURVEYS_TEST_ENABLED) {
			surveyLink = <li className={location.pathname === "/surveys" ? "active" : ""}>
				<Link to="/surveys" onClick={() => {
					trackContent("survey-menu");
				}}>Daily Surveys</Link>
			</li>;
		}else {
			surveyLink = <li className={location.pathname === "/cashback-surveys" ? "active" : ""}>
				<Link to="/cashback-surveys">Daily Surveys</Link>
			</li>
		}

		const loggedInLinks = (
			<div className="header-nav-container">
				<ul className="header-nav">
					<li className={location.pathname === "/tasks" ? "active" : ""}>
						<Link to="/tasks">Tasks</Link>
					</li>
					<li className={(location.pathname === "/bonus-offers" ? "active" : "") + hideBonusOffers}>
						<Link to="/bonus-offers">Bonus Offers</Link>
					</li>
					<li className={location.pathname === "/offer/torox" ? "active" : ""}>
						<Link to="/offer/torox">Extra Cashback</Link>
					</li>
					{surveyLink}
					<li className={location.pathname === "/how-it-works" ? "active" : ""}>
						<Link to="/how-it-works">How It Works</Link>
					</li>
					<li className={location.pathname === "/refer-and-earn" ? "active" : ""}>
						<Link to="/refer-and-earn">Refer and Earn</Link>
					</li>
					<li className={location.pathname === "/helpdesk" ? "active" : ""}><HelpdeskLoader /></li>
					<li className={location.pathname === "/earnings" ? "active" : ""}>
						<Link to="/earnings">My Cashback</Link>
					</li>
					<li><a href="https://blog.cashback.co.uk">Blog</a></li>
					<li className={location.pathname === "/my-account" || location.pathname === "/payment-details" ? "active" : ""}>
						<span className="header-dropdown" tabIndex={0} role="button"
							  onKeyUp={e => {
								  if (e.key === "Enter" || e.key === "Escape") {
									  this.handleShowDropdown();
								  }
							  }}
							  onClick={this.handleShowDropdown}>My Account
							<FontAwesomeIcon icon={faCaretDown} className="header-account-icon" />
							<ul className={"nav-dropdown " + (this.state.dropdownOpen ? "" : "hide")}>
								<FontAwesomeIcon
									icon={faCaretUp}
									className={"nav-dropdown-caret " + (this.state.dropdownOpen ? "" : "hide")} />
								<li><Link to="/my-account">Account details</Link></li>
								<li><Link to="/payment-details">Payment details</Link></li>
								<li><Link to="/logout">Logout</Link></li>
							</ul>
						</span>
					</li>
				</ul>
			</div>
		);

		const menuIcon = this.props.forLander ? (
			<img src="https://cashback-public.s3.eu-west-2.amazonaws.com/homepage/menu-white.svg"
				 alt="Open menu"
				 className="menu-icon"
				 width={30}
				 height={28}
			/>
		) : <FontAwesomeIcon icon={faBars} />;

		let partnerBrand;
		if (this.props.partnerBrand) {
			let brandImage;
			switch (this.props.partnerBrand) {
				case "researchTribe":
					brandImage =
						<img src="https://cashback-public.s3.eu-west-2.amazonaws.com/homepage/research-tribe-logo.png"
							 className="partner-logo"
							 alt="Research Tribe"
							 width="174"
							 height="128" />;
					break;

				case "opinionGroups":
					brandImage =
						<img src="https://cashback-public.s3.eu-west-2.amazonaws.com/homepage/opinion-groups-logo.png"
							 className="partner-logo"
							 alt="Opinion Groups"
							 width="128"
							 height="125" />;
					break;
			}
			partnerBrand = <>
				<span className="in-partnership">in partnership with</span>
				{brandImage}
			</>;
		}

		return (
			<div className={"header-outer " + (this.props.className ?? "")}>
				<div className="header-container">
					<header className="header">
						{this.props.showH1 === false ? <></> : <h1 className="hide">Welcome to Cashback: Earn Money
							Online</h1>}
						<Link to="/" className="main_logo">
							<img src="https://cashback-public.s3.eu-west-2.amazonaws.com/logo/cashback-and-logo.png"
								 className="logo-img"
								 alt="Home"
								 title="Home"
								 width="654"
								 height="140"
							/>
							{partnerBrand}
						</Link>
						{loggedIn ? loggedInLinks : loggedOutLinks}
						<div id="expand-menu"
							 className={loggedIn ? "logged-in" : ""}
							 tabIndex={0}
							 aria-label="Toggle menu"
							 role="button"
							 onKeyUp={e => {
								 if (e.key === "Enter" || e.key === "Escape") {
									 this.handleToggleMenu();
								 }
							 }}
							 onClick={this.handleToggleMenu}>
							{menuIcon}
						</div>
					</header>
				</div>
				<Menu className={loggedIn ? "logged-in" : ""}
					  show={this.state.menuExpanded}
					  handleClose={this.handleToggleMenu}
					  showModal={(modalType, trackAs) => {
						  this.props.showModal(modalType, trackAs);
					  }} />
				{this.props.children}
			</div>
		);
	}

}

Header.propTypes = {
	showModal: PropTypes.func,
	showH1: PropTypes.bool,
	forLander: PropTypes.bool,
	className: PropTypes.string
};

export default Header;