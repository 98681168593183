export function getParamFromUrl (param, key = false) {
	/* for when props cannot be used to store params */
	let url = new URL(window.location.href);

	if (key === true) {
		return url.searchParams.get(param);
	}

	url = url.toString();
	let res = url.split("/");
	let pos = res.indexOf(param);

	return res[pos + 1] ?? "";
}

export function objectToArgArray(obj){
	return Object.keys(obj)?.map(function(key) {
		if(Array.isArray(obj[key])){
			let arrayArg = [];
			obj[key].forEach((element, i) => {
				if(element){
					arrayArg.push(key + `[${i}]` + "=" + encodeURIComponent(element));
				}
			});
			return arrayArg.join('&');
		}
		return obj[key] ? key + "=" + encodeURIComponent(obj[key]) : null;
	});
}