import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getToken, trackContent } from "../utilities/tracking";
import { session } from "../utilities/storage";
import HelpdeskLoader from "./HelpdeskLoader";

class Menu extends Component {
	render () {
		const hideBonusOffersSources = ["tiktok"];
		let hideBonusOffers = "";
		if (hideBonusOffersSources.includes(session.getItem("source"))) {
			hideBonusOffers = " hide";
		}

		const loggedIn = getToken() !== null;
		const tabbable = { tabIndex: this.props.show ? 0 : -1 };
		const loggedOutLinks = (
			<>
				<li><a href="/signup" accessKey="s" {...tabbable}
					   onClick={(event) => {
						   event.preventDefault();
						   this.props.handleClose();
						   this.props.showModal("signup", "cta7");
					   }}>Sign Up</a></li>
				<li><a href="/login" accessKey="l" {...tabbable}
					   onClick={(event) => {
						   event.preventDefault();
						   this.props.handleClose();
						   this.props.showModal("login");
					   }}>Log In</a></li>
				<li><HelpdeskLoader /></li>
				<li><Link to="/faqs" {...tabbable}>FAQs</Link></li>
				<li><a href="https://blog.cashback.co.uk" {...tabbable}>Blog</a></li>
				<li><Link to="/privacy-notice" accessKey="p" {...tabbable}>Privacy Notice</Link></li>
				<li><Link to="/terms-and-conditions" accessKey="t" {...tabbable}>Terms &amp; Conditions</Link>
				</li>
				<li><Link to="/affiliates" {...tabbable}>Affiliates</Link></li>
			</>
		);

		let surveyLink = <li><Link to="/cashback-surveys" {...tabbable}>Daily Surveys</Link></li>;

		if (SURVEYS_TEST_ENABLED) {
			surveyLink = <li>
				<Link to="/surveys" {...tabbable}
					  onClick={() => {
						  trackContent("survey-menu");
					  }}>
					Daily Surveys
				</Link>
			</li>;
		}

		const loggedInLinks = (
			<>
				<li><Link to="/tasks" {...tabbable}>Tasks</Link></li>
				<li className={hideBonusOffers}><Link to="/bonus-offers" {...tabbable}>Bonus Offers</Link></li>
				<li><Link to="/offer/torox" {...tabbable}>Extra Cashback</Link></li>
				{surveyLink}
				<li><Link to="/how-it-works" {...tabbable}>How It Works</Link></li>
				<li><Link to="/refer-and-earn" {...tabbable}>Refer and Earn</Link></li>
				<li><HelpdeskLoader /></li>
				<li><Link to="/faqs" {...tabbable}>FAQs</Link></li>
				<li><Link to="/earnings" {...tabbable}>My Cashback</Link></li>
				<li><a href="https://blog.cashback.co.uk" {...tabbable}>Blog</a></li>
				<li><Link to="/my-account" {...tabbable}>Account Details</Link></li>
				<li><Link to="/payment-details" {...tabbable}>Payment Details</Link></li>
				<li><Link to="/privacy-notice" accessKey="p" {...tabbable}>Privacy Notice</Link></li>
				<li><Link to="/terms-and-conditions" accessKey="t" {...tabbable}>Terms &amp; Conditions</Link></li>
				<li><Link to="/affiliates" {...tabbable}>Affiliates</Link></li>
				<li><Link to="/earnings-disclaimer" {...tabbable}>Earnings Disclaimer</Link></li>
				<li><Link to="/logout" accessKey="x" {...tabbable}>Logout</Link></li>
			</>
		);

		return (
			<>
				<div className={"menu-overlay " + (this.props.show ? "" : "hide")}
					 onClick={this.props.handleClose} />
				<nav className={this.props.className + (this.props.show ? " menu" : " menu offscreen")}>
					<span className="menu-close" {...tabbable} role="button"
						  onKeyUp={e => {
							  if (e.key === "Enter" || e.key === "Escape") {
								  this.props.handleClose();
							  }
						  }}
						  onClick={this.props.handleClose}>×</span>
					<ul className="menu-list">
						{loggedIn ? loggedInLinks : loggedOutLinks}
					</ul>
				</nav>
			</>
		);
	}
}

export default Menu;