import React from "react";
import convertDataFunc from "../../utilities/convertDataFunc";
import { serviceUrls } from "../../Routes";
import { getToken } from "../../utilities/tracking";
import * as Sentry from "@sentry/react";

export default function handleSubmitFunc (dataFields, section, submitType,
	param = "", formatKeywords = false, customDataFields = null,
	onSuccess = () => {}, onFailure = () => {}) {

	if (!window.navigator.onLine) {
		onFailure("You are currently offline. Please try again when you are connected to the internet");
		return new Promise(() => {});
	}

	let token = getToken();
	let apiFields = {};
	let customDataApiFields = {};

	if (dataFields !== null) {
		Object.entries(dataFields).map(key => {
			let formName = key[0];

			if (formatKeywords) {
				apiFields[formName] = convertDataFunc(key, formatKeywords);
			} else {
				apiFields[formName] = convertDataFunc(key);
			}
		});
	}

	if (customDataFields !== null) {
		const requestDataOptions = {
			method: submitType,
			withCredentials: true,
			headers: new Headers({
				"Authorization": "Bearer " + token,
				"Content-Type": "application/json"
			}),
			body: JSON.stringify({
				data: customDataFields
			})
		};

		return fetch(`${serviceUrls().serverUrl}/${section}/data/${param}`, requestDataOptions)
			.then(response => {
				if (response.ok) return response.json();
				return response.json().then(res => {
					throw new Error(getErrorMessage(res));
				});
			})
			.then(json => {
				onSuccess(json);
			})
			.catch(error => {
				onFailure(error);
			});
	}

	if (param === "create-by-email" || param === "login-by-email" || param === "login-by-facebook") {
		const requestOptions = {
			method: submitType,
			cache: "no-cache",
			headers: new Headers({
				"Content-Type": "application/json"
			}),
			body: JSON.stringify(
				apiFields
			)
		};

		return fetch(`${serviceUrls().serverUrl}/${section}/${param}`, requestOptions)
			.then(response => {
				if (response.ok) return response.json();
				return response.json().then(res => {
					throw new Error(getErrorMessage(res));
				});
			})
			.then(json => {
				onSuccess(json);
			})
			.catch(error => {
				onFailure(error);
			});
	}

	const requestOptions = {
		method: submitType,
		withCredentials: true,
		cache: "no-cache",
		headers: new Headers({
			"Authorization": "Bearer " + token,
			"Content-Type": "application/json"
		}),
		body: JSON.stringify(
			apiFields
		)
	};

	return fetch(`${serviceUrls().serverUrl}/${section}/${param}`, requestOptions)
		.then(response => {
			if (response.ok) return response.json();
			return response.json().then(res => {
				Sentry.captureException(response.status + "\n" + response.url + "\n" + JSON.stringify(res));
				throw new Error(getErrorMessage(res));
			});
		})
		.then(json => {
			onSuccess(json);
		})
		.catch(error => {
			onFailure(error);
		});
}

export function getErrorMessage (json) {
	let errorsFound = [];

	if (json?.requirements?.failed?.password) {
		errorsFound.push(json.requirements.failed.password.message);
	}

	if (json?.requirements?.failed?.dob) {
		errorsFound.push(json.requirements.failed.dob.message);
	}

	if (json?.requirements?.failed?.mobile) {
		errorsFound.push(json.requirements.failed.mobile.message);
	}

	if (errorsFound.length > 0) {
		return errorsFound.join("/n");
	}

	return json.message;
}