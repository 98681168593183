export function retryPromiseWithDelay(promiseFn, delay, maxRetries = 5) {
	return new Promise((resolve, reject) => {
		const tryRequest = async (retryCount = 0) => {
			try {
				const response = await promiseFn();
				resolve(response);
			} catch (error) {
				if (retryCount < maxRetries) {
					setTimeout(() => {
						tryRequest(retryCount + 1);
					}, delay);
				} else {
					reject(error);
				}
			}
		};
		tryRequest();
	});
}