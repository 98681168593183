import React, { Component } from "react";
import { lookupOptionText } from "../utilities/TextLookups";

class Select extends Component {
	render () {
		let options;

		if (Array.isArray(this.props.options)) {
			options = this.props.options.map(option => {
				return (
					<option key={option} value={option}>
						{lookupOptionText(this.props.formType, this.props.name, option)}
					</option>
				);
			});
		} else {
			options = Object.entries(this.props.options).map(([k, value]) => {
				return (
					<option key={k} value={k}>
						{value}
					</option>
				);
			});
		}

		return (
			<div className={"select" + (this.props.fieldType === "hidden" ? " hidden" : "")}>
				<select id={this.props.name}
						name={this.props.name}
						onChange={this.props.onChange}
						required={this.props.required}
						value={this.props.value}
				>
					<option />
					{options}
				</select>
			</div>
		);
	}
}

export default Select;