import React, { Component } from "react";
import PropTypes from "prop-types";

class QuestionPrefs extends Component {
	render () {
		return (
			<>
				<p className="survey-question">{"Q" + (this.props.questionNumber || 3) +
					" of " + (this.props.totalQuestions || 3) + ": Which of these categories would you "+
					"like us to email you about?"}</p>

				<label htmlFor="all">
					<input type="checkbox"
						   id="all"
						   name="all"
						   value="cbuk_survey_offer_prefs"
						   onClick={allBox => {
							   // clear all
							   this.props.onClick(allBox);

							   const checkboxes = document.querySelectorAll("input[type=checkbox]:not(#all)");
							   checkboxes.forEach(box => {
								   if (allBox.target.checked) {
									   box.checked = false;
									   box.click();
								   }
								   box.checked = allBox.target.checked;
							   });
						   }}
					/>
					All
				</label>

				<label htmlFor="box1">
					<input type="checkbox"
						   name="cbuk_survey_offer_prefs"
						   value="cbuk_survey_offer_prefs_survey"
						   id="box1"
						   onClick={this.props.onClick}
					/>
					Surveys
				</label>

				<label htmlFor="box2">
					<input type="checkbox"
						   name="cbuk_survey_offer_prefs"
						   value="cbuk_survey_offer_prefs_gambling"
						   id="box2"
						   onClick={this.props.onClick}
					/>
					Gambling
				</label>

				<label htmlFor="box4">
					<input type="checkbox"
						   name="cbuk_survey_offer_prefs"
						   value="cbuk_survey_offer_prefs_earnmoney"
						   id="box4"
						   onClick={this.props.onClick}
					/>
					Earn money
				</label>

				<label htmlFor="box5">
					<input type="checkbox"
						   name="cbuk_survey_offer_prefs"
						   value="cbuk_survey_offer_prefs_comps"
						   id="box5"
						   onClick={this.props.onClick}
					/>
					Competitions
				</label>

				<label htmlFor="box6">
					<input type="checkbox"
						   name="cbuk_survey_offer_prefs"
						   value="cbuk_survey_offer_prefs_freetrials"
						   id="box6"
						   onClick={this.props.onClick}
					/>
					Free trials
				</label>

				<label htmlFor="box9">
					<input type="checkbox"
						   name="cbuk_survey_offer_prefs"
						   value="cbuk_survey_offer_prefs_kids"
						   id="box9"
						   onClick={this.props.onClick}
					/>
					Kids
				</label>

				<label htmlFor="box10">
					<input type="checkbox"
						   name="cbuk_survey_offer_prefs"
						   value="cbuk_survey_offer_prefs_pets"
						   id="box10"
						   onClick={this.props.onClick}
					/>
					Pets
				</label>

				<label htmlFor="box11">
					<input type="checkbox"
						   name="cbuk_survey_offer_prefs"
						   value="cbuk_survey_offer_prefs_samples"
						   id="box11"
						   onClick={this.props.onClick}
					/>
					Samples
				</label>

				<label htmlFor="box12">
					<input type="checkbox"
						   name="cbuk_survey_offer_prefs"
						   value="cbuk_survey_offer_prefs_food"
						   id="box12"
						   onClick={this.props.onClick}
					/>
					Food
				</label>
			</>
		);
	}
}

QuestionPrefs.propTypes = {
	questionNumber: PropTypes.number,
	totalQuestions: PropTypes.number,
	onClick: PropTypes.func.isRequired
};

export default QuestionPrefs;