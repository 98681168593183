import React, { Component } from "react";
import { set, add, format } from "date-fns";
import { zeroPad } from "../utilities/stringFuncs";

class DateSelects extends Component {
	constructor (props) {
		super(props);

		this.state = {
			day: props.value.day,
			month: props.value.month,
			year: props.value.year
		};

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange (event) {
		const name = event.target.name;
		const value = event.target.value;

		let date = this.state;
		date[name] = value;
		const dateString = `${date.year}-${zeroPad(date.month, 2)}-${zeroPad(date.day, 2)}`;

		this.setState({ [name]: value });

		this.props.onChange({
			name: this.props.name,
			value: dateString
		});
	}

	componentDidUpdate (prevProps) {
		if (prevProps.value.day !== this.props.value.day) {
			this.setState({ day: this.props.value.day });
		}

		if (prevProps.value.month !== this.props.value.month) {
			this.setState({ month: this.props.value.month });
		}

		if (prevProps.value.year !== this.props.value.year) {
			this.setState({ year: this.props.value.year });
		}
	}

	render () {
		const startDate = set(new Date(), { month: 0, day: 1 });
		let daysOptions = [<option key="0" disabled={true} value="">Day</option>];
		let monthsOptions = [<option key="0" disabled={true} value="">Month</option>];
		let yearsOptions = [<option key="0" disabled={true} value="">Year</option>];

		for (let d = 1; d <= 31; d++) {
			daysOptions.push(<option key={d} value={zeroPad(d, 2)}>{d}</option>);
		}

		for (let m = 0; m < 12; m++) {
			const month = add(startDate, { months: m });
			monthsOptions.push(<option key={format(month, "L")}
									   value={format(month, "LL")}>{format(month, "LLLL")}</option>);
		}

		for (let y = 0; y > -100; y--) {
			const date = add(startDate, { years: y });
			const year = format(date, "y");
			yearsOptions.push(<option key={year} value={year}>{year}</option>);
		}

		const { day, month, year } = this.state;

		return (
			<div id={this.props.name}>
				<select className={this.props.name + "-day"}
						name="day"
						value={day}
						onChange={this.handleChange}>
					{daysOptions}
				</select>
				<select className={this.props.name + "-month"}
						name="month"
						value={month}
						onChange={this.handleChange}>
					{monthsOptions}
				</select>
				<select className={this.props.name + "-year"}
						name="year"
						value={year}
						onChange={this.handleChange}>
					{yearsOptions}
				</select>
			</div>
		);
	}
}

export default DateSelects;