import React from "react";
import { getToken, getUserUuid } from "./tracking";

/**
 * Enum string values
 * @enum {string}
 */
const lookupTypes = {
	BRAND: "brand",
	CATEGORY: "category",
	LADDER_OFFERS: "ladder_offers",
	NETWORK: "network",
	OFFER: "offer",
	RUNG: "rung",
	USER: "user",
	PAYMENT_DETAILS: "payment_details"
};

const textLookups = {
	common: {
		"dob": "Date of birth",
		"email": "E-mail",
		"mobile": "Phone",
		"gender-male": "Male",
		"gender-female": "Female",
		"gender-rather not say": "Prefer not to say",
		"ladder_total": "Tasks total",
		"completed_total": "Confirmed total",
		"completed": "Confirmed",
		"bacs": "Bank Transfer"
	},
	offer: {},
	network: {},
	category: {},
	brand: {},
	user: {},
	rung: {},
	ladder_offers: {},
	payment_details: {}
};

const names = ["ID", "UUID", "URL", "IP", "I", "GBP", "USD", "AUD", "EUR", "HTML",
	"CSS", "iOS", "PayPal", "January", "February", "March", "April", "May",
	"June", "July", "August", "September", "October", "November", "December"];

String.prototype.toSentenceCase = function() {
	if (this.includes("@") || this.includes("://")) {
		return this;
	}

	const string =
		this.replace(/_/g, " ")
			.replace(/\w\S*/g, function(txt) {
				const found = names.find(word => word.toLowerCase() === txt.toLowerCase());
				if (found !== undefined) {
					return found;
				}
				return txt.toLowerCase();
			});
	return string.charAt(0).toUpperCase() + string.slice(1);
};

function lookupText (type, name) {
	if (name in textLookups[type]) {
		return textLookups[type][name];
	}

	if (name in textLookups.common) {
		return textLookups.common[name];
	}

	if (typeof name == "string") {
		return name.toSentenceCase();
	}
	return name;
}

function lookupOptionText (type, name, option) {
	const optionName = name + "-" + option;
	if (optionName in textLookups[type]) {
		return textLookups[type][optionName];
	}

	if (optionName in textLookups.common) {
		return textLookups.common[optionName];
	}

	return option;
}

function lookupHelp (type, name) {
	const helpName = name + "-help";

	let lookedUp = "";

	if (helpName in textLookups[type] && textLookups[type][helpName] !== "") {
		lookedUp = textLookups[type][helpName];
	}

	if (helpName in textLookups.common && textLookups.common[helpName] !== "") {
		lookedUp = textLookups.common[helpName];
	}

	return <p className="tooltip">{lookedUp}</p>;
}

export function replaceVariables (stringIn) {
	return stringIn.replace(/%%TOKEN%%/g, getToken() ?? "")
		.replace(/%%USERUUID%%/g, getUserUuid() ?? "");
}

export { lookupText, lookupOptionText, lookupHelp, lookupTypes };